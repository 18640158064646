import { mergeMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CustomerCreditActions from '../actions/customer-credit.actions';
import { CustomerCreditService } from '@app/customer-credit/services/customer-credit.service';
import { CustomerCredit } from '@app/core/models/customer-credit.model';

@Injectable()
export class CustomerCreditEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCreditActions.getCustomerCreditRequest),
      mergeMap(({ id }) =>
        this.customerCreditService.get(id).pipe(
          map((customerCredit: CustomerCredit) =>
            CustomerCreditActions.getCustomerCreditSuccess({
              customerCredit,
            })
          ),
          catchError((error) =>
            of(
              CustomerCreditActions.getCustomerCreditFailed({
                error,
              })
            )
          )
        )
      )
    )
  );
  getByCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCreditActions.getCustomerCreditByCustomerRequest),
      mergeMap(({ id }) =>
        this.customerCreditService.getAllByCustomerId(id).pipe(
          map((customerCredit: CustomerCredit[]) =>
            CustomerCreditActions.getCustomerCreditByCustomerSuccess({
              customerCredit,
            })
          ),
          catchError((error) =>
            of(
              CustomerCreditActions.getCustomerCreditByCustomerFailed({
                error,
              })
            )
          )
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCreditActions.addCustomerCreditRequest),
      mergeMap(({ addCustomerCreditInput }) =>
        this.customerCreditService.create(addCustomerCreditInput).pipe(
          map((response: CustomerCredit) =>
            CustomerCreditActions.addCustomerCreditSuccess({
              customerCredit: response,
            })
          ),
          catchError((error) =>
            of(CustomerCreditActions.addCustomerCreditFailed({ error }))
          )
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCreditActions.removeCustomerCreditRequest),
      mergeMap(({ id }) =>
        this.customerCreditService.delete(id).pipe(
          map(() => CustomerCreditActions.removeCustomerCreditSuccess({ id })),
          catchError((error) =>
            of(CustomerCreditActions.removeCustomerCreditFailed({ error }))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCreditActions.updateCustomerCreditRequest),
      mergeMap(({ editCustomerCreditInput }) =>
        this.customerCreditService.update(editCustomerCreditInput).pipe(
          map((customerCredit: CustomerCredit) =>
            CustomerCreditActions.updateCustomerCreditSuccess({
              customerCredit,
            })
          ),
          catchError((error) =>
            of(CustomerCreditActions.updateCustomerCreditFailed({ error }))
          )
        )
      )
    )
  );

  addToWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCreditActions.addCreditToWorkOrderRequest),
      mergeMap(({ addCreditToWorkOrderInput }) =>
        this.customerCreditService
          .addToWorkOrder(addCreditToWorkOrderInput)
          .pipe(
            map((customerCredit: CustomerCredit) =>
              CustomerCreditActions.addCreditToWorkOrderSuccess({
                customerCredit,
              })
            ),
            catchError((error) =>
              of(CustomerCreditActions.addCreditToWorkOrderFailed({ error }))
            )
          )
      )
    )
  );

  constructor(
    private customerCreditService: CustomerCreditService,
    private actions$: Actions
  ) {}
}
