import { IAppState } from '@store/state/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IQuoteState } from '@store/state/quote.state';
import { adapter } from '@store/reducers/quote.reducers';
import { Dictionary } from '@ngrx/entity';
import { Quote, AlphaSection, QuoteSection } from '@app/core/models';
import { EQuoteType } from '@app/core/enums';

const quoteOrder = [
  EQuoteType.Carpet,
  EQuoteType.Stairs,
  EQuoteType.Area_Rug,
  EQuoteType.Upholstery,
  EQuoteType.Hard_Surface,
  EQuoteType.Protector,
  EQuoteType.Other,
];

const selectQuotes = (state: IAppState) => state.quotes;

export const selectQuoteState = createFeatureSelector<IQuoteState>('quotes');

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

// select the dictionary of user entities
export const selectQuoteEntities = createSelector(
  selectQuoteState,
  selectEntities
);
// select the array of users
export const selectAllQuotes = createSelector(selectQuoteState, selectAll);

export const getSelectedQuote = createSelector(
  selectQuotes,
  (state: IQuoteState) => state.selectedQuote
);

export const selectLoading = createSelector(
  selectQuotes,
  (state: IQuoteState) => state.loading
);
export const selectError = createSelector(
  selectQuotes,
  (state: IQuoteState) => state.error
);

export const selectQuoteById = (id) =>
  createSelector(selectQuotes, (state: IQuoteState) => {
    if (id?.id) {
      id = id.id;
    }
    return state.entities[id];
  });

export const selectQuotesByType = createSelector(
  selectQuotes,
  (state: IQuoteState) => {
    const entityArray = Object.values(state.entities);
    if (entityArray && entityArray.length > 0) {
      const quoteSplit: QuoteSection<Quote>[] = Object.values(
        entityArray.reduce((quotes, quote) => {
          const quoteTypeName = quote.quoteTypeDesc;
          if (!quotes[quoteTypeName]) {
            quotes[quoteTypeName] = {
              title: quoteTypeName,
              quoteType: quote.quoteType,
              data: [quote],
            };
          } else {
            quotes[quoteTypeName].data.push(quote);
          }
          return quotes;
        }, {})
      );
      return quoteSplit.sort((a, b) => {
        return (
          quoteOrder.indexOf(a.quoteType) - quoteOrder.indexOf(b.quoteType)
        );
      });
    }
    return null;
  }
);

export const selectQuotesByTypeWithEmpty = createSelector(
  selectQuotes,
  (state: IQuoteState) => {
    const entityArray = Object.values(state.entities);
    if (entityArray) {
      const quoteTypeValues: string[] = Object.keys(EQuoteType).filter((key) =>
        isNaN(Number(EQuoteType[key]))
      );

      const quoteSplit: QuoteSection<Quote>[] = Object.values(
        entityArray.reduce((quotes, quote) => {
          const quoteTypeName = quote.quoteTypeDesc;
          if (!quotes[quoteTypeName]) {
            quotes[quoteTypeName] = {
              title: quoteTypeName,
              quoteType: quote.quoteType,
              data: [quote],
            };
          } else {
            quotes[quoteTypeName].data.push(quote);
          }
          return quotes;
        }, {})
      );
      for (const quoteType of quoteTypeValues) {
        let contains = false;
        for (const quote of quoteSplit) {
          if (quote && quote.quoteType.toString() === quoteType.toString()) {
            quote.data.sort((a, b) =>
              a.floor !== b.floor ? (a.floor < b.floor ? -1 : 1) : 0
            );
            contains = true;
          }
        }
        if (!contains) {
          quoteSplit.push({
            title: EQuoteType[quoteType].replace(/_/g, ' '),
            quoteType: +quoteType,
            data: [],
          });
        }
      }
      return quoteSplit.sort((a, b) => {
        return (
          quoteOrder.indexOf(a.quoteType) - quoteOrder.indexOf(b.quoteType)
        );
      });
    }
    return null;
  }
);

export const selectTotalCost = createSelector(
  selectQuotes,
  (state: IQuoteState) => {
    let total = 0;
    const entityArray = Object.values(state.entities);
    for (const quote of entityArray) {
      total +=
        quote.quantity * (quote.unitPrice * (quote.width * quote.length));
    }
    if (total < 150) total = 150;
    return total;
  }
);

export const selectTotalByQuoteType = (quoteType) =>
  createSelector(selectQuotes, (state: IQuoteState) => {
    let total = 0;
    const entityArray = Object.values(state.entities);
    for (const quote of entityArray) {
      if (quote.quoteType === quoteType) {
        const totalQuote =
          quote.quantity * (quote.unitPrice * (quote.width * quote.length));
        const totalDiscount = quote.discountPrice;
        const totalExtraFee = quote.extraFeePrice;
        total += evenRound(totalQuote - totalDiscount + totalExtraFee, 3);
      }
    }
    return total;
  });

export const selectFabricProtectorTotal = createSelector(
  selectQuotes,
  (state: IQuoteState) => {
    let total = 0;
    const entityArray = Object.values(state.entities);
    for (const quote of entityArray) {
      if (
        quote.quoteType === EQuoteType.Protector &&
        quote.description === 'Fabric Protector'
      ) {
        total += quote.totalPrice;
      }
    }
    return total;
  }
);

export const selectCarpetProtectorTotal = createSelector(
  selectQuotes,
  (state: IQuoteState) => {
    let total = 0;
    const entityArray = Object.values(state.entities);
    for (const quote of entityArray) {
      if (
        quote.quoteType === EQuoteType.Protector &&
        quote.description === 'Carpet Protector'
      ) {
        total += quote.totalPrice;
      }
    }
    return total;
  }
);

export const selectRugProtectorTotal = createSelector(
  selectQuotes,
  (state: IQuoteState) => {
    let total = 0;
    const entityArray = Object.values(state.entities);
    for (const quote of entityArray) {
      if (
        quote.quoteType === EQuoteType.Protector &&
        quote.description === 'Rug Protector'
      ) {
        total += quote.totalPrice;
      }
    }
    return total;
  }
);

function evenRound(num, decimalPlaces) {
  var d = decimalPlaces || 0;
  var m = Math.pow(10, d);
  var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  var i = Math.floor(n),
    f = n - i;
  var e = 1e-8; // Allow for rounding errors in f
  var r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
}

export const selectTotalSquareFeetByQuoteType = (quoteType) =>
  createSelector(selectQuotes, (state: IQuoteState) => {
    let total = 0;
    const entityArray = Object.values(state.entities);
    for (const quote of entityArray) {
      if (quote.quoteType === quoteType) {
        total += quote.quantity * quote.width * quote.length;
      }
    }
    return total;
  });

const getRouterState = createFeatureSelector('routerReducer');
