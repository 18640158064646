export enum EOtherType {
  Peroxide_Prespray = 'Peroxide in Prespray',
  Peroxide_Concentrate = 'Peroxide Concentrate',
  Heavy_Soiling = 'Heavy Soiling',
  Restoritive_Clean = 'Restoritive Clean',
  Urine_Enzyme = 'Urine Enzyme by the gallon',
  Rotary_Extraction = 'Rotary Extraction',
  Deodorizer_In_Rinse = 'Deodorizer In Rinse',
  Deoderizer_In_Prespray = 'Deoderizer In Prespray',
  Stripe_Wax = 'Stripe Wax',
  Wax_Removal = 'Wax Removal',
  Rust_Removal = 'Rust Removal',
  Gum_Removal = 'Gum Removal',
  Pick_Up = 'Pick Up',
  Delivery = 'Delivery',
  Pick_Up_Delivery = 'Pick Up and Delivery',
  Pre_Vacuum = 'Pre Vacuum',
  Vanish_Fusion = 'Vanish Fusion',
  Pet_Stain_Off = 'Pet Stain Off',
  Pet_Pack = 'Pet Pack',
  Homecare_Spotter = 'Homecare Spotter',
  Encap_Spotter = 'Encap Spotter',
  Spot_Solv = 'Spot Solv',
  Rubber_Rake = 'Rubber Rake',
  Perky_Rake = 'Perky Rake',
  Grandi_Groomer = 'Grandi Groomer',
  Bissell_Little_Green_Machine = 'Bissell Little Green Machine',
  Corner_Guards = 'Corner Guards',
}
