import { ActionReducerMap } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import { IAppState } from '../state/app.state';
import { authReducers } from './auth.reducers';
import { customerReducers } from './customer.reducers';
import { buildingReducers } from './building.reducers';
import { workOrderReducers } from './work-order.reducers';
import { quoteReducers } from './quote.reducers';
import { errorReducers } from './error.reducers';
import { cleaningCostTierReducers } from './cleaning-cost-tier.reducers';
import { cleaningCostReducers } from './cleaning-cost.reducers';
import { customerCreditReducers } from './customer-credit.reducers';
import { userReducers } from './user.reducers';

export const appReducers: ActionReducerMap<IAppState, any> = {
  router: routerReducer,
  auth: authReducers,
  customers: customerReducers,
  buildings: buildingReducers,
  workOrders: workOrderReducers,
  quotes: quoteReducers,
  customerCredit: customerCreditReducers,
  users: userReducers,
  errors: errorReducers,
  cleaningCostTiers: cleaningCostTierReducers,
  cleaningCosts: cleaningCostReducers,
};
