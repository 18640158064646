import { IUserState } from '../state/user.state';
import * as Actions from '../actions/user.actions';
import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { User } from '@app/core/models';

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialUserState: IUserState = adapter.getInitialState({
  selectedUser: null,
  selectedUserId: null,
  loaded: false,
  loading: false,
  loadingList: false,
  error: null,
});

export const userReducers = createReducer(
  initialUserState,
  on(Actions.selectUserRequest, (state, { id }) => ({
    ...state,
    selectedUser: state.entities[id],
  })),
  on(Actions.getUserRequest, (state, { id }) => ({
    ...state,
    selectedUser: null,
    loading: true,
    error: null,
  })),
  on(Actions.getUserSuccess, (state, { user }) => ({
    ...state,
    selectedUser: user,
    loading: false,
    loaded: true,
    error: null,
  })),
  on(Actions.getUsersFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.getUsersRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.getUsersSuccess, (state, { users }) => {
    return adapter.setAll(users, {
      ...state,
      loading: false,
      loaded: true,
      error: null,
    });
  }),
  on(Actions.getUserFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.addUserRequest, (state, { addUserInput }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.addUserSuccess, (state, { user }) => {
    return adapter.addOne(user, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.addUserFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.updateUserRequest, (state, { editUserInput }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.updateUserSuccess, (state, { user }) => {
    return adapter.upsertOne(user, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.updateUserFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.removeUserRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.removeUserSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.removeUserFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),

  on(Actions.resetPassword, (state, { resetPasswordDto }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.resetPasswordSuccess, (state, { success }) => ({
    ...state,
    loading: false,
  })),
  on(Actions.resetPasswordFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
