import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User, ApiResponse } from '@app/core/models';
import { map, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SessionService extends BaseService {
  constructor(protected http: HttpClient) {
    super('Session', http);
  }
  public getCurrentUserDetails(): Observable<any> {
    return this.http.get(this.apiUrl + '/GetCurrentLoginInformations').pipe(
      map((response: ApiResponse) => response.result),
      share()
    );
  }
}
