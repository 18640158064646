import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { appReducers } from './store/reducers/app.reducers';

import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AuthEffects } from '@store/effects/auth.effects';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './shared/auth/auth.interceptor';
import { CustomerEffects } from './store/effects/customer.effects';
import { BuildingEffects } from './store/effects/building.effects';
import { WorkOrderEffects } from './store/effects/work-order.effects';
import { QuoteEffects } from './store/effects/quote.effects';
import { CommonModule } from '@angular/common';
import { ErrorInterceptor } from './core/interceptors/error-interceptor';
import { ToastModule } from 'primeng-lts/toast';
import { CleaningCostTierEffects } from './store/effects/cleaning-cost-tier.effects';
import { CleaningCostEffects } from './store/effects/cleaning-cost.effects';
import { ErrorEffects } from './store/effects/error.effects';
import { CustomerCreditEffects } from './store/effects/customer-credit.effects';
import { UserEffects } from './store/effects/user.effects';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([
      AuthEffects,
      CustomerEffects,
      BuildingEffects,
      WorkOrderEffects,
      QuoteEffects,
      CustomerCreditEffects,
      CleaningCostTierEffects,
      CleaningCostEffects,
      ErrorEffects,
      UserEffects,
    ]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
