import { ICleaningCostState } from '../state/cleaning-cost.state';
import * as Actions from '../actions/cleaning-cost.actions';
import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CleaningCost } from '@app/core/models';

export const adapter: EntityAdapter<CleaningCost> = createEntityAdapter<
  CleaningCost
>();

export const initialCleaningCostState: ICleaningCostState = adapter.getInitialState(
  {
    selectedCleaningCost: null,
    selectedCleaningCostId: null,
    loaded: false,
    loading: false,
    error: null,
  }
);

export const cleaningCostReducers = createReducer(
  initialCleaningCostState,
  on(Actions.getCleaningCostRequest, (state, { id }) => ({
    ...state,
    selectedCleaningCost: null,
    loading: true,
    error: null,
  })),
  on(Actions.getCleaningCostSuccess, (state, { cleaningCost }) => ({
    ...state,
    selectedCleaningCost: cleaningCost,
    loading: false,
    loaded: true,
    error: null,
  })),
  on(Actions.getCleaningCostsFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.getCleaningCostsRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.getCleaningCostsSuccess, (state, { cleaningCosts }) => {
    return adapter.setAll(cleaningCosts, {
      ...state,
      loading: false,
      loaded: true,
      error: null,
    });
  }),
  on(Actions.getCleaningCostFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.addCleaningCostRequest, (state, { addCleaningCostInput }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.addCleaningCostSuccess, (state, { cleaningCost }) => {
    return adapter.addOne(cleaningCost, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.addCleaningCostFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.updateCleaningCostRequest, (state, { editCleaningCostInput }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.updateCleaningCostSuccess, (state, { cleaningCost }) => {
    return adapter.upsertOne(cleaningCost, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.updateCleaningCostFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.removeCleaningCostRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.removeCleaningCostSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.removeCleaningCostFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
