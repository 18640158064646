import { mergeMap, map, catchError, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
// import { QuoteService } from '@app/quotes/services/quotes.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as QuoteActions from '../actions/quote.actions';
import * as QuoteSelectors from '../selectors/quote.selectors';
import * as WorkOrderActions from '@store/actions/work-order.actions';
import { QuoteService } from '@app/quotes/services/quote.service';
import { Quote } from '@app/core/models/quote.model';
import { Store } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { ApiResponse, UpdateUnitPricesInput } from '@app/core/models';

@Injectable()
export class QuoteEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActions.getQuoteRequest),
      mergeMap(({ id }) =>
        this.quoteService.get(id).pipe(
          map((quote: Quote) => QuoteActions.getQuoteSuccess({ quote })),
          catchError((error) =>
            of(
              QuoteActions.getQuoteFailed({
                error,
              })
            )
          )
        )
      )
    )
  );
  getByWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        QuoteActions.getQuotesByWorkOrderRequest,
        QuoteActions.getQuotesByWorkOrderRequestFromEffect
      ),
      mergeMap(({ id }) =>
        this.quoteService.getQuotesByWorkOrder(id).pipe(
          map((quotes: Quote[]) =>
            QuoteActions.getQuotesByWorkOrderSuccess({ quotes })
          ),
          catchError((error) =>
            of(
              QuoteActions.getQuotesByWorkOrderFailed({
                error,
              })
            )
          )
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActions.addQuoteRequest),
      mergeMap(({ addQuoteInput }) =>
        this.quoteService
          .create(addQuoteInput)
          .pipe(
            map((response: Quote) =>
              QuoteActions.addQuoteSuccess({ quote: response })
            ),
            catchError((error) => of(QuoteActions.addQuoteFailed({ error })))
          )
          .pipe(
            tap(() =>
              this.store.dispatch(
                WorkOrderActions.getWorkOrderRequest({
                  id: addQuoteInput.workOrderId,
                })
              )
            ),
            tap(() =>
              this.store.dispatch(
                QuoteActions.getQuotesByWorkOrderRequest({
                  id: addQuoteInput.workOrderId,
                })
              )
            )
          )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActions.removeQuoteRequest),
      mergeMap(({ deleteQuoteInput }) =>
        this.quoteService
          .delete(deleteQuoteInput.id)
          .pipe(
            map(() =>
              QuoteActions.removeQuoteSuccess({ id: deleteQuoteInput.id })
            ),
            catchError((error) => of(QuoteActions.removeQuoteFailed({ error })))
          )
          .pipe(
            tap(() =>
              this.store.dispatch(
                WorkOrderActions.getWorkOrderRequest({
                  id: deleteQuoteInput.workOrderId,
                })
              )
            ),
            tap(() =>
              this.store.dispatch(
                QuoteActions.getQuotesByWorkOrderRequest({
                  id: deleteQuoteInput.workOrderId,
                })
              )
            )
          )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActions.updateQuoteRequest),
      mergeMap(({ editQuoteInput }) =>
        this.quoteService
          .update(editQuoteInput)
          .pipe(
            map((quote: Quote) => QuoteActions.updateQuoteSuccess({ quote })),
            catchError((error) => of(QuoteActions.updateQuoteFailed({ error })))
          )
          .pipe(
            tap(() =>
              this.store.dispatch(
                WorkOrderActions.getWorkOrderRequest({
                  id: editQuoteInput.workOrderId,
                })
              )
            ),
            tap(() =>
              this.store.dispatch(
                QuoteActions.getQuotesByWorkOrderRequest({
                  id: editQuoteInput.workOrderId,
                })
              )
            )
          )
      )
    )
  );

  select$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActions.selectQuoteRequest),
      mergeMap(({ id }) =>
        this.store.select(QuoteSelectors.selectQuoteById({ id })).pipe(
          map((quote: Quote) => {
            return quote
              ? QuoteActions.selectQuoteSuccess({ quote })
              : QuoteActions.selectQuoteFailed({
                  error: 'Unable to find quote',
                });
          }),
          catchError((error) => of(QuoteActions.selectQuoteFailed({ error })))
        )
      )
    )
  );

  duplicate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuoteActions.duplicateQuoteRequest),
      mergeMap(({ duplicateQuoteInput }) =>
        this.quoteService.duplicate<Quote>(duplicateQuoteInput).pipe(
          map((quote) => QuoteActions.duplicateQuoteSuccess({ quote })),
          catchError((error) =>
            of(QuoteActions.duplicateQuoteFailed({ error }))
          )
        )
      )
    )
  );
  duplicateSuccess$ = createEffect(
    () =>
      this.actions$
        .pipe(
          ofType(QuoteActions.duplicateQuoteSuccess),
          tap(({ quote }) => {
            this.store.dispatch(
              QuoteActions.selectQuoteRequest({ id: quote.id })
            );
          })
        )
        .pipe(
          tap(({ quote }) =>
            this.store.dispatch(
              WorkOrderActions.getWorkOrderRequest({
                id: quote.workOrderId,
              })
            )
          ),
          tap(({ quote }) =>
            this.store.dispatch(
              QuoteActions.getQuotesByWorkOrderRequest({
                id: quote.workOrderId,
              })
            )
          )
        ),
    { dispatch: false }
  );

  constructor(
    private quoteService: QuoteService,
    private actions$: Actions,
    private store: Store<IAppState>
  ) {}
}
