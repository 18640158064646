import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomerSearchInput } from '@app/core/models/customer-search-input.model';
import { catchError, tap, map, share } from 'rxjs/operators';
import { ApiResponse } from '@app/core/models';
import { Customer } from '@app/core/models/customer.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends BaseService {
  constructor(protected http: HttpClient) {
    super('Customer', http);
  }

  searchCustomers(searchInput: CustomerSearchInput): Observable<Customer[]> {
    // Trim the search term
    var term = searchInput.term.trim();
    return this.http
      .get(
        this.apiUrl +
          '/Search?term=' +
          term +
          '&searchType=' +
          searchInput.searchType
      )
      .pipe(
        map((response: ApiResponse) => response.result.items as Customer[]),
        share()
      );
  }
}
