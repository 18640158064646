import { IAppState } from '@app/store/state/app.state';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { ErrorService } from '@app/shared/services/error.service';
import * as ErrorActions from '@store/actions/error.actions';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store<IAppState>) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        // check for internet connection
        if (!navigator.onLine) {
          console.warn('No internet connection');
        }

        console.log(error);
        // let errorMessage = '';
        // if (error.error instanceof ErrorEvent) {
        //   // client-side error
        //   errorMessage = `Error: ${error.error.message}`;
        // } else {
        //   // server-side error
        //   errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        // }

        // handle HTTP errors
        switch (error.status) {
          case 401:
            // do something
            console.log('401 error');
            break;
          case 403:
            // do something else
            console.error('403 error. Redirect to login');
            break;
          case 404:
            // do something else
            console.log('404 error');
            break;
          default:
          // default behavior
        }
        this.store.dispatch(ErrorActions.addGlobalError({ error }));
        return throwError(error);
      })
    );
  }
}
