import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap, map, share } from 'rxjs/operators';
import { ApiResponse } from '@app/core/models';
import { Quote } from '@app/core/models/quote.model';

@Injectable({
  providedIn: 'root',
})
export class QuoteService extends BaseService {
  constructor(protected http: HttpClient) {
    super('Quote', http);
  }

  getQuotesByWorkOrder(id: number): Observable<Quote[]> {
    return this.http.get(this.apiUrl + '/GetByWorkOrderId?id=' + id).pipe(
      map((response: ApiResponse) => response.result.items as Quote[]),
      share()
    );
  }
  duplicate<T>(data: any): Observable<T> {
    return this.http.post(`${this.apiUrl}/Duplicate`, data).pipe(
      map((response: ApiResponse) => response.result as T),
      share()
    );
  }
}
