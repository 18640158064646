export enum EMaterialType {
  Nylon = 'Nylon',
  Polyester = 'Polyester',
  Olefin = 'Olefin',
  Wool = 'Wool',
  Rayon = 'Rayon',
  Cotton = 'Cotton',
  Nylon_Berber = 'Nylon/Berber',
  Olefin_Berber = 'Olefin/Berber',
  Polyester_Shag = 'Polyester/Shag',
  Nylon_Frise = 'Nylon/Frise',
  Polyester_Frise = 'Polyester/Frise',
  Microfibre = 'Microfibre',
}
