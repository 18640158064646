import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import * as ErrorActions from '@store/actions/error.actions';

@Injectable()
export class ErrorEffects {
  globalError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorActions.addGlobalError),
      map((error) => {
        let errorMessage: string;
        if (error?.error?.error?.error?.details) {
          errorMessage = error?.error?.error?.error?.details;
        } else if (error?.error?.error?.error?.message) {
          errorMessage = error?.error?.error?.error?.message;
        } else {
          errorMessage = error?.error?.message;
        }
        return errorMessage;
      }),
      map((errorMessage) =>
        ErrorActions.addGlobalErrorMessage({ errorMessage })
      )
    )
  );

  constructor(private actions$: Actions) {}
}
