import { createAction, props } from '@ngrx/store';
import { Quote } from '@app/core/models/quote.model';
import {
  AddQuoteInput,
  DuplicateQuoteInput,
  EditQuoteInput,
  UpdateUnitPricesInput,
} from '@app/core/models';
import { DeleteQuoteInput } from '@app/core/models/delete-quote-input.model';

export const getQuoteRequest = createAction(
  '[WorkOrder Details Page] Get Quote Details',
  props<{ id: number }>()
);
export const getQuoteSuccess = createAction(
  '[Quote API] Get Quote Details Success',
  props<{ quote: Quote }>()
);
export const getQuoteFailed = createAction(
  '[Quote API] Get Quote Details Failed',
  props<{ error: string }>()
);

export const getQuotesRequest = createAction('[Quotes] Get Quotes Request');
export const getQuotesSuccess = createAction(
  '[Quotes API] Get Quotes Success',
  props<{ quotes: Quote[] }>()
);
export const getQuotesFailed = createAction(
  '[Quotes API] Get Quotes Failed',
  props<{ error: string }>()
);

export const getQuotesByWorkOrderRequest = createAction(
  '[Work Order Details Page] Get Quotes by Work Order Request',
  props<{ id: number }>()
);
export const getQuotesByWorkOrderRequestFromEffect = createAction(
  '[Work Order Effect] Get Quotes by Work Order Request',
  props<{ id: number }>()
);
export const getQuotesByWorkOrderSuccess = createAction(
  '[Quotes API] Get Quotes by Work Order Success',
  props<{ quotes: Quote[] }>()
);
export const getQuotesByWorkOrderFailed = createAction(
  '[Quotes API] Get Quotes by Work Order Failed',
  props<{ error: string }>()
);

export const addQuoteRequest = createAction(
  '[Quote Details] Add Quote Request',
  props<{ addQuoteInput: AddQuoteInput }>()
);
export const addQuoteSuccess = createAction(
  '[Quotes API] Add Quote Success',
  props<{ quote: Quote }>()
);
export const addQuoteFailed = createAction(
  '[Quotes API] Add Quote Failed',
  props<{ error: string }>()
);

export const updateQuoteRequest = createAction(
  '[Quote Details] Update Quote Request',
  props<{ editQuoteInput: EditQuoteInput }>()
);
export const updateQuoteSuccess = createAction(
  '[Quote API] Update Quote Success',
  props<{ quote: Quote }>()
);
export const updateQuoteFailed = createAction(
  '[Quote API] Update Quote Failed',
  props<{ error: string }>()
);

export const removeQuoteRequest = createAction(
  '[Quote Details] Remove Quote Request',
  props<{ deleteQuoteInput: DeleteQuoteInput }>()
);
export const removeQuoteSuccess = createAction(
  '[Quote API] Remove Quote Success',
  props<{ id: number }>()
);
export const removeQuoteFailed = createAction(
  '[Quote API] Remove Quote Failed',
  props<{ error: string }>()
);

export const selectQuoteRequest = createAction(
  '[Quote List] Select Quote',
  props<{ id: number | null }>()
);
export const selectQuoteSuccess = createAction(
  '[Quote Store] Select Quote Success',
  props<{ quote: Quote }>()
);
export const selectQuoteFailed = createAction(
  '[Quote Store] Select Quote Failed',
  props<{ error: string }>()
);

export const duplicateQuoteRequest = createAction(
  '[Quote Details] Duplicate Quote Request',
  props<{ duplicateQuoteInput: DuplicateQuoteInput }>()
);
export const duplicateQuoteSuccess = createAction(
  '[Quote API] Duplicate Quote Success',
  props<{ quote: Quote }>()
);
export const duplicateQuoteFailed = createAction(
  '[Quote API] Duplicate Quote Failed',
  props<{ error: string }>()
);
export const clearQuotes = createAction('[Quote] Clear Quotes');
