import { ICustomerState } from '../state/customer.state';
import * as Actions from '../actions/customer.actions';
import { createReducer, on } from '@ngrx/store';
import { Customer } from '@app/core/models/customer.model';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export const adapter: EntityAdapter<Customer> = createEntityAdapter<Customer>();

export const initialCustomerState: ICustomerState = adapter.getInitialState({
  selectedCustomer: null,
  selectedCustomerId: null,
  customerSearchList: [],
  loaded: false,
  loading: false,
  loadingList: false,
  error: null,
});

export const customerReducers = createReducer(
  initialCustomerState,
  on(
    Actions.searchForCustomer,
    // Actions.customerSearchPageSearchTerm,
    (state, { searchInput }) => ({
      ...state,
      loadingList: true,
      error: null,
    })
  ),
  on(Actions.searchForCustomerSuccess, (state, { customers }) => {
    return adapter.setAll(customers, {
      ...state,
      loadingList: false,
      loaded: true,
      error: null,
    });
  }),
  on(Actions.searchForCustomerFailed, (state, { error }) => ({
    ...state,
    loadingList: false,
    error,
  })),
  on(Actions.getCustomerDetails, (state, { id }) => ({
    ...state,
    selectedCustomer: null,
    loading: true,
    error: null,
  })),
  on(Actions.getCustomerDetailsSuccess, (state, { customer }) => {
    return adapter.upsertOne(customer, {
      ...state,
      selectedCustomer: customer,
      loading: false,
      loaded: true,
      error: null,
    });
  }),
  on(Actions.getCustomerDetailsFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.getCustomersRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.getCustomersSuccess, (state, { customers }) => {
    return adapter.setAll(customers, {
      ...state,
      loading: false,
      loaded: true,
      error: null,
    });
  }),
  on(Actions.getCustomersFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.addCustomerRequest, (state, { addCustomerInput }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.addCustomerSuccess, (state, { customer }) => {
    return adapter.addOne(customer, {
      ...state,
      selectedCustomer: customer,
      loading: false,
      error: null,
    });
  }),
  on(Actions.addCustomerFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.updateCustomerRequest, (state, { addCustomerInput }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.updateCustomerSuccess, (state, { customer }) => {
    return adapter.upsertOne(customer, {
      ...state,
      selectedCustomer: customer,
      loading: false,
      error: null,
    });
  }),
  on(Actions.updateCustomerFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.removeCustomerRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.removeCustomerSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      selectedCustomer:
        state.selectedCustomer?.id === id ? null : state.selectedCustomer,
      loading: false,
      error: null,
    });
  }),
  on(Actions.removeCustomerFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(
    Actions.clearCustomers,
    Actions.clearCustomersSearch,
    Actions.deselectCustomer,
    (state) => {
      return adapter.removeAll({
        ...state,
        selectedCustomer: null,
      });
    }
  )
  // on(Actions.customerSearchSelectCustomer, (state, { customer }) => {
  //   return {
  //     ...state,
  //     loading: true,
  //     error: null,
  //   };
  // }),
  // on(Actions.selectCustomerSuccess, (state, { customer }) => {
  //   return {
  //     ...state,
  //     selectedCustomer: customer,
  //     error: null,
  //   };
  // })
);
