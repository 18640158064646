export enum EHardSurfaceType {
  Ceramic = 'Ceramic',
  Porcelain = 'Porcelain',
  Travertine = 'Travertine',
  Slate = 'Slate',
  Natural_Stone = 'Natural Stone',
  Granite = 'Granite',
  Quartz = 'Quartz',
  Marble = 'Marble',
  Limestone = 'Limestone',
  VCT = 'VCT Tile Scrub and Wax',
}
