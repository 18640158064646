import { createAction, props } from '@ngrx/store';
import {
  AddUserInput,
  EditUserInput,
  ResetPasswordDto,
  User,
} from '@app/core/models';

export const selectUserRequest = createAction(
  '[User] Select User',
  props<{ id: number }>()
);

export const getUserRequest = createAction(
  '[User Details Page] Get User Details',
  props<{ id: number }>()
);
export const getUserSuccess = createAction(
  '[User API] Get User Details Success',
  props<{ user: User }>()
);
export const getUserFailed = createAction(
  '[User API] Get User Details Failed',
  props<{ error: string }>()
);

export const getUsersRequest = createAction('[User] Get Users Request');
export const getUsersSuccess = createAction(
  '[User API] Get Users Success',
  props<{ users: User[] }>()
);
export const getUsersFailed = createAction(
  '[User API] Get Users Failed',
  props<{ error: string }>()
);

export const addUserRequest = createAction(
  '[User Details] Add User Request',
  props<{ addUserInput: AddUserInput }>()
);
export const addUserSuccess = createAction(
  '[User API] Add User Success',
  props<{ user: User }>()
);
export const addUserFailed = createAction(
  '[User API] Add User Failed',
  props<{ error: string }>()
);

export const updateUserRequest = createAction(
  '[User Details] Update User Request',
  props<{ editUserInput: EditUserInput }>()
);
export const updateUserSuccess = createAction(
  '[User API] Update User Success',
  props<{ user: User }>()
);
export const updateUserFailed = createAction(
  '[User API] Update User Failed',
  props<{ error: string }>()
);

export const removeUserRequest = createAction(
  '[User Details] Remove User Request',
  props<{ id: number }>()
);
export const removeUserSuccess = createAction(
  '[User API] Remove User Success',
  props<{ id: number }>()
);
export const removeUserFailed = createAction(
  '[User API] Remove User Failed',
  props<{ error: string }>()
);

export const resetPassword = createAction(
  '[User Details] Reset User Password Request',
  props<{ resetPasswordDto: ResetPasswordDto }>()
);
export const resetPasswordSuccess = createAction(
  '[User API] Reset User Password Success',
  props<{ success: boolean }>()
);
export const resetPasswordFailed = createAction(
  '[User API] Reset User Password Failed',
  props<{ error: string }>()
);
