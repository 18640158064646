import { mergeMap, map, catchError, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AuthActions from '../actions/auth.actions';
import { AuthService } from './../../shared/auth/auth.service';
import { ApiResponse } from './../../core/models';
import { Router } from '@angular/router';
import { IAppState } from '../state/app.state';
import { Store } from '@ngrx/store';
import { UserService } from './../../shared/services/user.service';
import { SessionService } from './../../shared/services/session.service';

@Injectable()
export class AuthEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getUserRequest),
      mergeMap(() =>
        this.sessionService.getCurrentUserDetails().pipe(
          tap((details) => {
            details.user.fullName =
              details.user?.name + ' ' + details.user?.surname;

            details.user.roleNames = this.authService.getFromJWT(
              'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            );
          }),
          map((details) => AuthActions.getUserSuccess({ user: details.user }))
        )
      )
      // mergeMap(() =>
      //   this.userService
      //     .getCurrentUserDetails()
      //     .pipe(map((user: User) => AuthActions.getUserSuccess({ user })))
      // )
    )
  );

  getStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginStatusRequest),
      map(() =>
        AuthActions.loginStatusSuccess({
          isLoggedIn: this.authService.isLoggedIn(),
        })
      )
    )
  );
  // getStatus$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthActions.selectGetStatus),
  //     mergeMap(() =>
  //       this.authService.login(creds[0], creds[1]).pipe(
  //         map((response: ApiResponse) => AuthActions.loginSuccess({ user: response.result })),
  //         catchError((err) => of(AuthActions.loginFailed({ error: err.message })))
  //       )
  //     )
  //   )
  // );

  loginRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginRequest, AuthActions.loginPageLoginRequest),
      mergeMap(({ creds }) =>
        this.authService.login(creds[0], creds[1]).pipe(
          map((response: ApiResponse) =>
            AuthActions.loginSuccess({ user: response.result })
          ),
          catchError((error) => of(AuthActions.loginFailed({ error })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap((response) => {
          this.authService.setSession(response.user);
        }),
        tap(() => {
          this.router.navigateByUrl('/customer');
        })
      ),
    { dispatch: false }
  );

  logoutRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logoutRequest, AuthActions.loginPageLogoutRequest),
      switchMap(() => {
        this.authService.logout();
        return of(AuthActions.logoutSuccess());
      }),
      catchError((error) => of(AuthActions.logoutFailed({ error })))
    )
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutSuccess),
        tap(() => {
          this.router.navigateByUrl('/');
        })
      ),
    { dispatch: false }
  );

  // @Effect({ dispatch: false })
  // LogInSuccess: Observable<any> = this.actions.pipe(
  //   ofType(AuthActionTypes.LOGIN_SUCCESS),
  //   tap((user) => {
  //     localStorage.setItem('token', user.payload.token);
  //     this.router.navigateByUrl('/');
  //   })
  // );

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private sessionService: SessionService,
    private store: Store<IAppState>,
    private actions$: Actions,
    private router: Router
  ) {}
}
