import { IWorkOrderState } from '../state/work-order.state';
import * as Actions from '../actions/work-order.actions';
import { createReducer, on } from '@ngrx/store';
import { WorkOrder } from '@app/core/models/work-order.model';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { state } from '@angular/animations';

export const adapter: EntityAdapter<WorkOrder> =
  createEntityAdapter<WorkOrder>();

export const initialWorkOrderState: IWorkOrderState = adapter.getInitialState({
  selectedWorkOrder: null,
  selectedWorkOrderId: null,
  scheduledWorkOrders: [],
  loaded: false,
  loading: false,
  loadingList: false,
  error: null,
});

export const workOrderReducers = createReducer(
  initialWorkOrderState,
  on(Actions.selectWorkOrderRequest, (state, { id }) => ({
    ...state,
    selectedWorkOrder: state.entities[id],
  })),
  on(Actions.getWorkOrderRequest, (state, { id }) => ({
    ...state,
    selectedWorkOrder: null,
    loading: true,
    error: null,
  })),
  on(Actions.getWorkOrderSuccess, (state, { workOrder }) => {
    return adapter.upsertOne(workOrder, {
      ...state,
      selectedWorkOrder: workOrder,
      loading: false,
      loaded: true,
      error: null,
    });
  }),
  on(Actions.getWorkOrderFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.getWorkOrdersRequest, (state) => ({
    ...state,
    loading: true,
    loadingList: true,
    error: null,
  })),
  on(Actions.getWorkOrdersSuccess, (state, { workOrders }) => {
    return adapter.setAll(workOrders, {
      ...state,
      loading: false,
      loadingList: false,
      loaded: true,
      error: null,
    });
  }),
  on(Actions.getWorkOrdersFailed, (state, { error }) => ({
    ...state,
    loading: false,
    loadingList: false,
    error,
  })),
  on(
    Actions.getWorkOrdersByCustomerRequest,
    Actions.getWorkOrdersByCustomerRequestFromCustomerEffect,
    (state, { id }) => ({
      ...state,
      loading: true,
      loadingList: true,
      error: null,
    })
  ),
  on(Actions.getWorkOrdersByCustomerSuccess, (state, { workOrders }) => {
    return adapter.setAll(workOrders, {
      ...state,
      loading: false,
      loadingList: false,
      loaded: true,
      error: null,
    });
  }),
  on(Actions.getWorkOrdersByCustomerFailed, (state, { error }) => ({
    ...state,
    loading: false,
    loadingList: false,
    error,
  })),
  on(Actions.addWorkOrderRequest, (state, { addWorkOrderInput }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.addWorkOrderSuccess, (state, { workOrder }) => {
    return adapter.addOne(workOrder, {
      ...state,
      selectedWorkOrder: workOrder,
      loading: false,
      error: null,
    });
  }),
  on(Actions.addWorkOrderFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.updateWorkOrderRequest, (state, { editWorkOrderInput }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.updateWorkOrderSuccess, (state, { workOrder }) => {
    return adapter.upsertOne(workOrder, {
      ...state,
      selectedWorkOrder: workOrder,
      loading: false,
      error: null,
    });
  }),
  on(Actions.updateWorkOrderFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.removeWorkOrderRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.removeWorkOrderSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      selectedWorkOrder:
        state.selectedWorkOrder?.id === id ? null : state.selectedWorkOrder,
      loading: false,
      error: null,
    });
  }),
  on(Actions.removeWorkOrderFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.duplicateWorkOrderRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.duplicateWorkOrderSuccess, (state, { workOrder }) => {
    return adapter.addOne(workOrder, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.duplicateWorkOrderFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.completeWorkOrderRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.completeWorkOrderSuccess, (state, { workOrder }) => {
    return adapter.upsertOne(workOrder, {
      ...state,
      selectedWorkOrder: workOrder,
      loading: false,
      error: null,
    });
  }),
  on(Actions.completeWorkOrderFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.clearSelectedWorkOrder, (state) => {
    return adapter.removeAll({
      ...state,
      selectedWorkOrder: null,
    });
  }),
  on(Actions.customerDetailsClearWorkOrders, (state) => {
    return adapter.removeAll({
      ...state,
      selectedQuote: null,
    });
  }),
  on(Actions.getScheduledWorkOrders, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.getScheduledWorkOrdersSuccess, (state, { workOrders }) => {
    return {
      ...state,
      scheduledWorkOrders: workOrders,
      loading: false,
      error: null,
    };
  }),
  on(Actions.getScheduledWorkOrdersFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
