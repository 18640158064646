import { createAction, props } from '@ngrx/store';
import {
  AddCustomerCreditInput,
  EditCustomerCreditInput,
  CustomerCredit,
  AddCreditToWorkOrderInput,
} from '@app/core/models';

export const getCustomerCreditRequest = createAction(
  '[Customer Credit Details Page] Get Customer Credit Details',
  props<{ id: number }>()
);
export const getCustomerCreditSuccess = createAction(
  '[Customer Credit API] Get Customer Credit Details Success',
  props<{ customerCredit: CustomerCredit }>()
);
export const getCustomerCreditFailed = createAction(
  '[Customer Credit API] Get Customer Credit Details Failed',
  props<{ error: string }>()
);

export const getAllCustomerCreditRequest = createAction(
  '[Customer Credit ] Get All Customer Credits Request'
);
export const getAllCustomerCreditSuccess = createAction(
  '[Customer Credit API] Get All Customer Credits Success',
  props<{ customerCredits: CustomerCredit[] }>()
);
export const getAllCustomerCreditFailed = createAction(
  '[Customer Credit API] Get All Customer Credits Failed',
  props<{ error: string }>()
);

export const addCustomerCreditRequest = createAction(
  '[Customer Credit Details] Add Customer Credit Request',
  props<{ addCustomerCreditInput: AddCustomerCreditInput }>()
);
export const addCustomerCreditSuccess = createAction(
  '[Customer Credit API] Add Customer Credit Success',
  props<{ customerCredit: CustomerCredit }>()
);
export const addCustomerCreditFailed = createAction(
  '[Customer Credit API] Add Customer Credit Failed',
  props<{ error: string }>()
);

export const updateCustomerCreditRequest = createAction(
  '[Customer Credit Details] Update Customer Credit Request',
  props<{ editCustomerCreditInput: EditCustomerCreditInput }>()
);
export const updateCustomerCreditSuccess = createAction(
  '[Customer Credit API] Update Customer Credit Success',
  props<{ customerCredit: CustomerCredit }>()
);
export const updateCustomerCreditFailed = createAction(
  '[Customer Credit API] Update Customer Credit Failed',
  props<{ error: string }>()
);

export const removeCustomerCreditRequest = createAction(
  '[Customer Credit Details] Remove Customer Credit Request',
  props<{ id: number }>()
);
export const removeCustomerCreditSuccess = createAction(
  '[Customer Credit API] Remove Customer Credit Success',
  props<{ id: number }>()
);
export const removeCustomerCreditFailed = createAction(
  '[Customer Credit API] Remove Customer Credit Failed',
  props<{ error: string }>()
);

export const getCustomerCreditByCustomerRequest = createAction(
  '[Customer Details Page] Get Customer Credit by Customer Request',
  props<{ id: number }>()
);
export const getCustomerCreditByCustomerSuccess = createAction(
  '[Customer Credit API] Get Customer Credit by Customer Success',
  props<{ customerCredit: CustomerCredit[] }>()
);
export const getCustomerCreditByCustomerFailed = createAction(
  '[Customer Credit API] Get Customer Credit by Customer Failed',
  props<{ error: string }>()
);

export const addCreditToWorkOrderRequest = createAction(
  '[Work Order Form Page] Add Customer Credit to Work Order Request',
  props<{ addCreditToWorkOrderInput: AddCreditToWorkOrderInput }>()
);
export const addCreditToWorkOrderSuccess = createAction(
  '[Customer Credit API] Add Customer Credit to Work Order Success',
  props<{ customerCredit: CustomerCredit }>()
);
export const addCreditToWorkOrderFailed = createAction(
  '[Customer Credit API] Add Customer Credit to Work Order Failed',
  props<{ error: string }>()
);
