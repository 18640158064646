import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { retry, catchError, tap, map, share } from 'rxjs/operators';
import { ApiResponse, ServerError } from '@app/core/models';
import { environment } from 'src/environments/environment';
import { Update } from '@ngrx/entity';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  protected apiUrl = environment.apiUrl;

  constructor(
    @Inject(String) protected apiName: string,
    protected http: HttpClient
  ) {
    this.apiUrl += apiName;
  }

  get<T>(id: number): Observable<T> {
    return this.http.get(`${this.apiUrl}/Get?id=${id}`).pipe(
      // retry(2),
      // catchError((error) => {
      //   console.log(error);
      // }),
      // tap((response) => console.log(response)),
      map((response: ApiResponse) => response.result as T),
      share()
    );
  }

  getAll<T>(): Observable<T> {
    return this.http.get(`${this.apiUrl}/GetAll`).pipe(
      // retry(2),
      map((response: ApiResponse) => response.result.items as T),
      share()
    );
  }

  getByName<T>(name: string): Observable<T> {
    return this.http.get(`${this.apiUrl}/GetByName?name=${name}`).pipe(
      // retry(2),
      // catchError((error) => {
      //   return this.handleError(error);
      // }),
      map((response: ApiResponse) => response.result as T),
      share()
    );
  }

  create<T>(data: any): Observable<T> {
    return this.http.post(`${this.apiUrl}/Create`, data).pipe(
      // catchError((error) => {
      //   return this.handleError(error);
      // }),
      map((response: ApiResponse) => response.result as T),
      share()
    );
  }

  update<T>(data: any): Observable<T> {
    return this.http.put(`${this.apiUrl}/Update`, data).pipe(
      // catchError((error) => {
      //   return this.handleError(error);
      // }),
      map((response: ApiResponse) => response.result as T),
      share()
    );
  }

  patch(item: Update<any>): Observable<any> {
    const jsonPatch = this.convertObjectToPatch(item.changes);
    return this.http.patch(`${this.apiUrl}/Patch?id=${item.id}`, jsonPatch);
  }

  delete<T>(id: number): Observable<T> {
    return this.http.delete(`${this.apiUrl}/Delete?id=${id}`).pipe(
      // catchError((error) => {
      //   return this.handleError(error);
      // }),
      map((response: ApiResponse) => response.result as T),
      share()
    );
  }

  search<T>(term: string): Observable<T[]> {
    return this.http.get(this.apiUrl + '?term=' + term).pipe(
      // catchError((error) => {
      //   return this.handleError(error);
      // }),
      map((response: ApiResponse) => response.result.items as T[]),
      share()
    );
  }

  // handleError(err: any): Observable<ServerError> {
  //   console.log(err);
  //   // in a real world app, we may send the server to some remote logging infrastructure
  //   // instead of just logging it to the console
  //   let error = new ServerError();
  //   // The backend returned an unsuccessful response code.
  //   // The response body may contain clues as to what went wrong,
  //   if (err.error  && err.error.error ) {
  //     error = err.error.error;
  //     error.code = err.status;
  //   } else {
  //     error = new ServerError();
  //     error.code = err.status;
  //     error.message = err.message;
  //     // `Backend returned code ${err.status}: ${err.message}`;
  //   }

  //   // this.loadingSubject.next(false);
  //   return throwError(error);
  // }

  convertObjectToPatch(item: any): any[] {
    const jsonPatch = [];
    Object.keys(item).forEach((key) => {
      const patchItem = {
        op: 'replace',
        path: `/${key}`,
        value: item[key],
      };
      jsonPatch.push(patchItem);
    });
    return jsonPatch;
  }
}
