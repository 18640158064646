export enum ECities {
  Barnwell = 'Barnwell',
  Calgary = 'Calgary',
  Coaldale = 'Coaldale',
  Coalhurst = 'Coalhurst',
  Diamond_City = 'Diamond City',
  Foremost = 'Foremost',
  Fort_Macleod = 'Fort Macleod',
  Granum = 'Granum',
  Grassy_Lake = 'Grassy Lake',
  Iron_Springs = 'Iron Springs',
  Lethbridge = 'Lethbridge',
  Little_Bow_Resort = 'Little Bow Resort',
  Lomond = 'Lomond',
  Magrath = 'Magrath',
  Milk_River = 'Milk River',
  Monarch = 'Monarch',
  Moon_River_Estates = 'Moon River Estates',
  Nobleford = 'Nobleford',
  Picture_Butte = 'Picture Butte',
  Raymond = 'Raymond',
  Shaughnessy = 'Shaughnessy',
  Stirling = 'Stirling',
  Taber = 'Taber',
  Vulcan = 'Vulcan',
  Welling = 'Welling',
}
