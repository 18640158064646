export enum EProvince {
  Alberta = 64,
  British_Columbia = 65,
  Manitoba = 66,
  New_Brunswick = 67,
  Newfoundland_and_Labrador = 68,
  Northwest_Territories = 69,
  Nova_Scotia = 70,
  Nunavut = 71,
  Ontario = 72,
  Prince_Edward_Island = 73,
  Quebec = 74,
  Saskatchewan = 75,
  Yukon = 76
}
