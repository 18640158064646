import { ICustomerCreditState } from '../state/customer-credit.state';
import * as Actions from '../actions/customer-credit.actions';
import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CustomerCredit } from '@app/core/models';

export const adapter: EntityAdapter<CustomerCredit> = createEntityAdapter<
  CustomerCredit
>();

export const initialCustomerCreditState: ICustomerCreditState = adapter.getInitialState(
  {
    selectedCustomerCredit: null,
    selectedCustomerCreditId: null,
    loaded: false,
    loading: false,
    error: null,
  }
);

export const customerCreditReducers = createReducer(
  initialCustomerCreditState,
  on(Actions.getCustomerCreditRequest, (state, { id }) => ({
    ...state,
    selectedCustomerCredit: null,
    loading: true,
    error: null,
  })),
  on(Actions.getCustomerCreditSuccess, (state, { customerCredit }) => ({
    ...state,
    selectedCustomerCredit: customerCredit,
    loading: false,
    loaded: true,
    error: null,
  })),
  on(Actions.getAllCustomerCreditRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.getAllCustomerCreditSuccess, (state, { customerCredits }) => {
    return adapter.setAll(customerCredits, {
      ...state,
      loading: false,
      loaded: true,
      error: null,
    });
  }),
  on(Actions.getAllCustomerCreditFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.getCustomerCreditFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.addCustomerCreditRequest, (state, { addCustomerCreditInput }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.addCustomerCreditSuccess, (state, { customerCredit }) => {
    return adapter.addOne(customerCredit, {
      ...state,
      selectedCustomerCredit: customerCredit,
      loading: false,
      error: null,
    });
  }),
  on(Actions.addCustomerCreditFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(
    Actions.updateCustomerCreditRequest,
    (state, { editCustomerCreditInput }) => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(Actions.updateCustomerCreditSuccess, (state, { customerCredit }) => {
    return adapter.upsertOne(customerCredit, {
      ...state,
      selectedCustomerCredit: customerCredit,
      loading: false,
      error: null,
    });
  }),
  on(Actions.updateCustomerCreditFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.removeCustomerCreditRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.removeCustomerCreditSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.removeCustomerCreditFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.getCustomerCreditByCustomerRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    Actions.getCustomerCreditByCustomerSuccess,
    (state, { customerCredit }) => {
      return adapter.setAll(customerCredit, {
        ...state,
        loading: false,
        loaded: true,
        error: null,
      });
    }
  ),
  on(Actions.getCustomerCreditByCustomerFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(
    Actions.addCreditToWorkOrderRequest,
    (state, { addCreditToWorkOrderInput }) => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(Actions.addCreditToWorkOrderSuccess, (state, { customerCredit }) => {
    return adapter.upsertOne(customerCredit, {
      ...state,
      selectedCustomerCredit: customerCredit,
      loading: false,
      error: null,
    });
  }),
  on(Actions.addCreditToWorkOrderFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
