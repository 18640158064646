import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CustomerCredit,
  ApiResponse,
  AddCreditToWorkOrderInput,
} from '@app/core/models';
import { map, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerCreditService extends BaseService {
  constructor(protected http: HttpClient) {
    super('CustomerCredit', http);
  }

  getAllByCustomerId(id: number): Observable<CustomerCredit[]> {
    return this.http.get(this.apiUrl + '/GetByCustomer?id=' + id).pipe(
      map((response: ApiResponse) => response.result.items as CustomerCredit[]),
      share()
    );
  }
  addToWorkOrder(
    addCreditToWorkOrderInput: AddCreditToWorkOrderInput
  ): Observable<CustomerCredit> {
    return this.http
      .post(this.apiUrl + '/AddCreditToWorkOrder', addCreditToWorkOrderInput)
      .pipe(
        map((response: ApiResponse) => response.result as CustomerCredit),
        share()
      );
  }
}
