import {
  mergeMap,
  map,
  catchError,
  take,
  tap,
  concatMap,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
// import { CustomerService } from '@app/customers/services/customers.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CustomerActions from '../actions/customer.actions';
import * as WorkOrderActions from '../actions/work-order.actions';
import * as BuildingActions from '../actions/building.actions';
import { CustomerService } from '@app/customers/services/customer.service';
import { Customer } from '@app/core/models/customer.model';
import { IAppState } from '../state/app.state';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CustomerCredit } from '@app/core/models';
import { CustomerCreditService } from '@app/customer-credit/services/customer-credit.service';

@Injectable()
export class CustomerEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.getCustomerDetails),
      mergeMap(({ id }) =>
        this.customerService.get<Customer>(id).pipe(
          concatMap((customer: Customer) => {
            return this.customerCreditService.getAllByCustomerId(id).pipe(
              tap(
                (customerCredit: CustomerCredit[]) =>
                  (customer.customerCredit = customerCredit)
              ),
              map(() => customer)
            );
          }),
          map((customer: Customer) =>
            CustomerActions.getCustomerDetailsSuccess({ customer })
          ),
          catchError((error) =>
            of(CustomerActions.getCustomerDetailsFailed({ error }))
          )
        )
      )
    )
  );

  getAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.getCustomersRequest),
      mergeMap(() =>
        this.customerService.getAll<Customer[]>().pipe(
          map((customers) =>
            CustomerActions.getCustomersSuccess({ customers })
          ),
          catchError((err) =>
            of(CustomerActions.getCustomersFailed({ error: err.message }))
          )
        )
      )
    )
  );

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.searchForCustomer),
      mergeMap(({ searchInput }) =>
        this.customerService.searchCustomers(searchInput).pipe(
          map((customers) =>
            CustomerActions.searchForCustomerSuccess({ customers })
          ),
          catchError((error) =>
            of(CustomerActions.searchForCustomerFailed({ error }))
          )
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.addCustomerRequest),
      mergeMap(({ addCustomerInput }) =>
        this.customerService.create(addCustomerInput).pipe(
          map((response: Customer) =>
            CustomerActions.addCustomerSuccess({ customer: response })
          ),
          catchError((error) =>
            of(CustomerActions.addCustomerFailed({ error }))
          )
        )
      )
    )
  );
  // createSuccess$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(
  //         CustomerActions.addCustomerSuccess
  //       ),
  //       tap(({ customer }) => {
  //         this.store.dispatch(BuildingActions.g);
  //       })
  //     ),
  //   { dispatch: false }
  // );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.removeCustomerRequest),
      mergeMap(({ id }) =>
        this.customerService.delete(id).pipe(
          map(() => CustomerActions.removeCustomerSuccess({ id })),
          catchError((error) =>
            of(CustomerActions.removeCustomerFailed({ error }))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.updateCustomerRequest),
      mergeMap(({ addCustomerInput }) =>
        this.customerService.update(addCustomerInput).pipe(
          map((customer: Customer) =>
            CustomerActions.updateCustomerSuccess({ customer })
          ),
          catchError((error) =>
            of(CustomerActions.updateCustomerFailed({ error }))
          )
        )
      )
    )
  );
  addUpdateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CustomerActions.updateCustomerSuccess,
          CustomerActions.addCustomerSuccess
        ),
        tap(({ customer }) => {
          this.router.navigate(['/customer', customer.id]);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private customerService: CustomerService,
    private customerCreditService: CustomerCreditService,
    private actions$: Actions,
    private store: Store<IAppState>,
    private router: Router
  ) {}
}
