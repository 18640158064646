import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User, ApiResponse, ResetPasswordDto } from '@app/core/models';
import { AuthService } from '../auth/auth.service';
import { map, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(protected http: HttpClient, private authService: AuthService) {
    super('User', http);
  }
  public getCurrentUserDetails(): Observable<User> {
    const id = this.authService.getFromJWT('sub');
    return this.http.get(this.apiUrl + '/Get?Id=' + id).pipe(
      map((response: ApiResponse) => response.result as User),
      share()
    );
  }

  public resetPassword(
    resetPasswordDto: ResetPasswordDto
  ): Observable<boolean> {
    return this.http
      .post(this.apiUrl + '/ResetPassword', resetPasswordDto)
      .pipe(map((response: ApiResponse) => response.result as boolean));
  }
}
