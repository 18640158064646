export enum EColorType {
  Beige = 'Beige',
  Creme = 'Creme',
  Grey = 'Grey',
  Brown = 'Brown',
  Tan = 'Tan',
  Black = 'Black',
  Blue = 'Blue',
  Green = 'Green',
  Rose = 'Rose',
  Peach = 'Peach',
  Burgundy = 'Burgundy',
  Multi_Color = 'Multi Color',
  Pattern = 'Pattern',
}
