import { IBuildingState } from '../state/building.state';
import * as Actions from '../actions/building.actions';
import { createReducer, on } from '@ngrx/store';
import { Building } from '@app/core/models/building.model';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { state } from '@angular/animations';

export const adapter: EntityAdapter<Building> = createEntityAdapter<Building>();

export const initialBuildingState: IBuildingState = adapter.getInitialState({
  selectedBuilding: null,
  selectedBuildingId: null,
  maintenanceOverdueCount: 0,
  loaded: false,
  loading: false,
  error: null,
});

export const buildingReducers = createReducer(
  initialBuildingState,
  on(Actions.selectBuildingRequest, (state, { id }) => ({
    ...state,
    selectedBuilding: state.entities[id],
  })),
  on(Actions.getBuildingRequest, (state, { id }) => ({
    ...state,
    selectedBuilding: null,
    loading: true,
    error: null,
  })),
  on(Actions.getBuildingSuccess, (state, { building }) => ({
    ...state,
    selectedBuilding: building,
    loading: false,
    loaded: true,
    error: null,
  })),
  on(Actions.getBuildingFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(
    Actions.getBuildingsRequest,
    Actions.getBuildingsWithMaintenanceRequest,
    (state) => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(Actions.getBuildingsSuccess, (state, { buildings }) => {
    return adapter.setAll(buildings, {
      ...state,
      loading: false,
      loaded: true,
      error: null,
    });
  }),
  on(Actions.getBuildingsFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(
    Actions.getBuildingsByCustomerRequest,
    Actions.getBuildingsByCustomerRequestFromCustomerEffect,
    (state, { id }) => ({
      ...state,
      // selectedBuilding: null,
      loading: true,
      error: null,
    })
  ),
  on(Actions.getBuildingsByCustomerSuccess, (state, { buildings }) => {
    return adapter.setAll(buildings, {
      ...state,
      loading: false,
      loaded: true,
      error: null,
    });
  }),
  on(Actions.getBuildingsByCustomerFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.addBuildingRequest, (state, { addBuildingInput }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.addBuildingSuccess, (state, { building }) => {
    return adapter.addOne(building, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.addBuildingFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.updateBuildingRequest, (state, { editBuildingInput }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.updateBuildingSuccess, (state, { building }) => {
    return adapter.upsertOne(building, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.updateBuildingFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.removeBuildingRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.removeBuildingSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.removeBuildingFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.getOverdueMaintenanceCountRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.getOverdueMaintenanceCountSuccess, (state, { count }) => ({
    ...state,
    maintenanceOverdueCount: count,
    loading: false,
    error: null,
  })),
  on(Actions.getOverdueMaintenanceCountFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.customerDetailsClearBuildings, (state) => {
    return adapter.removeAll({
      ...state,
      selectedQuote: null,
    });
  })
);
