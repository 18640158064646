<div class="login p-grid p-justify-center p-align-center">
  <div class="login-container p-col-fixed">
    <div class="p-grid p-dir-col">
      <div class="p-col p-text-center p-mb-4">
        <img src="/assets/images/logo_white.png" />
      </div>
      <div class="p-col p-text-center color-white p-text-light p-mb-3">
        <h2>Sign in to AquaSteam</h2>
      </div>
      <div class="p-col">
        <div [ngSwitch]="!loggedIn">
          <div *ngSwitchCase="true">
            <form [formGroup]="form" (submit)="login()">
              <div class="ui-fluid">
                <div class="p-field">
                  <label for="email">Email Address</label>
                  <input
                    id="email"
                    type="text"
                    pInputText
                    formControlName="email"
                  />
                </div>
                <div class="p-field">
                  <label for="password">Password</label>
                  <input
                    id="password"
                    type="password"
                    pInputText
                    formControlName="password"
                  />
                </div>
                <div class="p-field p-mt-6">
                  <button
                    pButton
                    styleClass="full-width"
                    type="submit"
                    label="Let's Go"
                    (click)="login()"
                    class="full-width"
                    [disabled]="loading || !form.valid"
                  ></button>
                </div>
              </div>
              <app-progress-spinner [loading]="loading"></app-progress-spinner>
            </form>
          </div>

          <div
            *ngSwitchCase="false"
            class="navbar-item has-dropdown is-hoverable profile"
          >
            <button
              pButton
              type="button"
              label="Log Out"
              (click)="logout()"
              [disabled]="loading"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
