export enum EUpholsteryType {
  Couch = 'Couch',
  Loveseat = 'Loveseat',
  Recliner = 'Recliner',
  Chair = 'Chair',
  Ottoman = 'Ottoman',
  Sectional = 'Sectional',
  Loose_Back_Cushion = 'Loose Back Cushion',
  Pillow = 'Pillow',
  Dining_Chair = 'Dining Chair',
  Stool = 'Stool',
  Desk_Chair = 'Desk Chair',
  Office_Reception_Chair = 'Office/Reception Chair',
  Single_Mattress = 'Single Mattress',
  Double_Mattress = 'Double Mattress',
  King_Mattress = 'King Mattress',
  Bench = 'Bench',
  Chaise = 'Chaise',
}
