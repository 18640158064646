import { mergeMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CleaningCostActions from '../actions/cleaning-cost.actions';
import { CleaningCostService } from '@app/cleaning-costs/services/cleaning-cost.service';
import { CleaningCost } from '@app/core/models/cleaning-cost.model';

@Injectable()
export class CleaningCostEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CleaningCostActions.getCleaningCostRequest),
      mergeMap(({ id }) =>
        this.cleaningCostService.get(id).pipe(
          map((cleaningCost: CleaningCost) =>
            CleaningCostActions.getCleaningCostSuccess({
              cleaningCost,
            })
          ),
          catchError((error) =>
            of(
              CleaningCostActions.getCleaningCostFailed({
                error,
              })
            )
          )
        )
      )
    )
  );

  getAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CleaningCostActions.getCleaningCostsRequest),
      mergeMap(() =>
        this.cleaningCostService.getAll<CleaningCost[]>().pipe(
          map((cleaningCosts) =>
            CleaningCostActions.getCleaningCostsSuccess({ cleaningCosts })
          ),
          catchError((error) =>
            of(CleaningCostActions.getCleaningCostsFailed({ error }))
          )
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CleaningCostActions.addCleaningCostRequest),
      mergeMap(({ addCleaningCostInput }) =>
        this.cleaningCostService.create(addCleaningCostInput).pipe(
          map((response: CleaningCost) =>
            CleaningCostActions.addCleaningCostSuccess({
              cleaningCost: response,
            })
          ),
          catchError((error) =>
            of(CleaningCostActions.addCleaningCostFailed({ error }))
          )
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CleaningCostActions.removeCleaningCostRequest),
      mergeMap(({ id }) =>
        this.cleaningCostService.delete(id).pipe(
          map(() => CleaningCostActions.removeCleaningCostSuccess({ id })),
          catchError((error) =>
            of(CleaningCostActions.removeCleaningCostFailed({ error }))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CleaningCostActions.updateCleaningCostRequest),
      mergeMap(({ editCleaningCostInput }) =>
        this.cleaningCostService.update(editCleaningCostInput).pipe(
          map((cleaningCost: CleaningCost) =>
            CleaningCostActions.updateCleaningCostSuccess({
              cleaningCost,
            })
          ),
          catchError((error) =>
            of(CleaningCostActions.updateCleaningCostFailed({ error }))
          )
        )
      )
    )
  );

  constructor(
    private cleaningCostService: CleaningCostService,
    private actions$: Actions
  ) {}
}
