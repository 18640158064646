import { ICleaningCostTierState } from '../state/cleaning-cost-tier.state';
import * as Actions from '../actions/cleaning-cost-tier.actions';
import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CleaningCostTier } from '@app/core/models';

export const adapter: EntityAdapter<CleaningCostTier> = createEntityAdapter<
  CleaningCostTier
>();

export const initialCleaningCostTierState: ICleaningCostTierState = adapter.getInitialState(
  {
    selectedCleaningCostTier: null,
    selectedCleaningCostTierId: null,
    loaded: false,
    loading: false,
    error: null,
  }
);

export const cleaningCostTierReducers = createReducer(
  initialCleaningCostTierState,
  on(Actions.getCleaningCostTierRequest, (state, { id }) => ({
    ...state,
    selectedCleaningCostTier: null,
    loading: true,
    error: null,
  })),
  on(Actions.getCleaningCostTierSuccess, (state, { cleaningCostTier }) => ({
    ...state,
    selectedCleaningCostTier: cleaningCostTier,
    loading: false,
    loaded: true,
    error: null,
  })),
  on(Actions.getCleaningCostTierFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.getCleaningCostTiersRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.getCleaningCostTiersSuccess, (state, { cleaningCostTiers }) => {
    return adapter.setAll(cleaningCostTiers, {
      ...state,
      loading: false,
      loaded: true,
      error: null,
    });
  }),
  on(Actions.getCleaningCostTiersFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(
    Actions.addCleaningCostTierRequest,
    (state, { addCleaningCostTierInput }) => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(Actions.addCleaningCostTierSuccess, (state, { cleaningCostTier }) => {
    return adapter.addOne(cleaningCostTier, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.addCleaningCostTierFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(
    Actions.updateCleaningCostTierRequest,
    (state, { editCleaningCostTierInput }) => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(Actions.updateCleaningCostTierSuccess, (state, { cleaningCostTier }) => {
    return adapter.upsertOne(cleaningCostTier, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.updateCleaningCostTierFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.removeCleaningCostTierRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.removeCleaningCostTierSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.removeCleaningCostTierFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }))
);
