import { createAction, props } from '@ngrx/store';
import {
  AddCleaningCostInput,
  EditCleaningCostInput,
  CleaningCost,
} from '@app/core/models';

export const getCleaningCostRequest = createAction(
  '[Cleaning Cost Details Page] Get Cleaning Cost Details',
  props<{ id: number }>()
);
export const getCleaningCostSuccess = createAction(
  '[Cleaning Cost API] Get Cleaning Cost Details Success',
  props<{ cleaningCost: CleaningCost }>()
);
export const getCleaningCostFailed = createAction(
  '[Cleaning Cost API] Get Cleaning Cost Details Failed',
  props<{ error: string }>()
);

export const getCleaningCostsRequest = createAction(
  '[Cleaning Cost] Get Cleaning Costs Request'
);
export const getCleaningCostsSuccess = createAction(
  '[Cleaning Cost API] Get Cleaning Costs Success',
  props<{ cleaningCosts: CleaningCost[] }>()
);
export const getCleaningCostsFailed = createAction(
  '[Cleaning Cost API] Get Cleaning Costs Failed',
  props<{ error: string }>()
);

export const addCleaningCostRequest = createAction(
  '[Cleaning Cost Details] Add Cleaning Cost Request',
  props<{ addCleaningCostInput: AddCleaningCostInput }>()
);
export const addCleaningCostSuccess = createAction(
  '[Cleaning Cost API] Add Cleaning Cost Success',
  props<{ cleaningCost: CleaningCost }>()
);
export const addCleaningCostFailed = createAction(
  '[Cleaning Cost API] Add Cleaning Cost Failed',
  props<{ error: string }>()
);

export const updateCleaningCostRequest = createAction(
  '[Cleaning Cost Details] Update Cleaning Cost Request',
  props<{ editCleaningCostInput: EditCleaningCostInput }>()
);
export const updateCleaningCostSuccess = createAction(
  '[Cleaning Cost API] Update Cleaning Cost Success',
  props<{ cleaningCost: CleaningCost }>()
);
export const updateCleaningCostFailed = createAction(
  '[Cleaning Cost API] Update Cleaning Cost Failed',
  props<{ error: string }>()
);

export const removeCleaningCostRequest = createAction(
  '[Cleaning Cost Details] Remove Cleaning Cost Request',
  props<{ id: number }>()
);
export const removeCleaningCostSuccess = createAction(
  '[Cleaning Cost API] Remove Cleaning Cost Success',
  props<{ id: number }>()
);
export const removeCleaningCostFailed = createAction(
  '[Cleaning Cost API] Remove Cleaning Cost Failed',
  props<{ error: string }>()
);
