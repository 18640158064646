import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/error.actions';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ServerError } from '@app/core/models';
import { IErrorState } from '../state/error.state';
import { HttpErrorResponse } from '@angular/common/http';

export const adapter: EntityAdapter<HttpErrorResponse> = createEntityAdapter<HttpErrorResponse>();

export const initialErrorState: IErrorState = adapter.getInitialState({
  error: null,
  errorMessage: null,
});

export const errorReducers = createReducer(
  initialErrorState,
  on(Actions.addGlobalError, (state, { error }) => ({
    ...state,
    error,
  })),
  on(Actions.addGlobalErrorMessage, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  }))
);
