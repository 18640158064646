export enum EUpholsteryMaterialType {
  Nylon = 'Nylon',
  Polyester = 'Polyester',
  Olefin = 'Olefin',
  Wool = 'Wool',
  Rayon = 'Rayon',
  Cotton = 'Cotton',
  Linen = 'Linen',
  Microfibre = 'Microfibre',
  Acrylic = 'Acrylic',
}
