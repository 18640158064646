import { Component, OnInit, OnDestroy } from '@angular/core';
import { tap, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAppState } from './store/state/app.state';
import * as AuthActions from '@store/actions/auth.actions';
import * as AuthSelectors from '@store/selectors/auth.selectors';
import * as ErrorSelectors from '@store/selectors/error.selectors';
import { PrimeNGConfig, MessageService } from 'primeng-lts/api';
import { Router, NavigationEnd } from '@angular/router';
import { UrlService } from './shared/services/url.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'AquaSteam';
  previousUrl: string = null;
  currentUrl: string = null;

  loggedIn$ = this.store
    .select(AuthSelectors.selectLoggedIn)
    .pipe(
      tap((response) => {
        if (response === true) {
          this.store.dispatch(AuthActions.getUserRequest());
        }
      })
    )
    .subscribe();

  error$ = this.store
    .select(ErrorSelectors.selectErrorMessage)
    .pipe(
      tap((errorMessage) => {
        if (errorMessage) {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: errorMessage,
            sticky: true,
            closable: true,
          });
        }
      })
    )
    .subscribe();

  constructor(
    private store: Store<IAppState>,
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private urlService: UrlService,
    private messageService: MessageService
  ) {
    this.store.dispatch(AuthActions.loginStatusRequest());
    // authService.getInitialLoggedInStatus();
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.urlService.setPreviousUrl(this.previousUrl);
      });
  }

  ngOnDestroy(): void {
    this.loggedIn$.unsubscribe();
    this.error$.unsubscribe();
  }
}
