<!-- <p-progressSpinner
  class="spinner"
  *ngIf="loading || loading == null"
  strokeWidth="6"
  animationDuration=".5s"
  [style]="{ width: size + 'px', height: size + 'px' }"
></p-progressSpinner> -->
<ngx-spinner
  bdColor="rgba(255,255,255,0.8)"
  size="default"
  color="#1375f8"
  type="ball-fall"
  [fullScreen]="false"
  [zIndex]="1"
></ngx-spinner>
