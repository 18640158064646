import { IQuoteState } from '../state/quote.state';
import * as Actions from '../actions/quote.actions';
import { createReducer, on } from '@ngrx/store';
import { Quote } from '@app/core/models/quote.model';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

export const adapter: EntityAdapter<Quote> = createEntityAdapter<Quote>();

export const initialQuoteState: IQuoteState = adapter.getInitialState({
  selectedQuote: null,
  selectedQuoteId: null,
  loading: false,
  error: null,
});

export const quoteReducers = createReducer(
  initialQuoteState,
  on(Actions.getQuoteRequest, (state, { id }) => ({
    ...state,
    selectedQuote: null,
    loading: true,
    error: null,
  })),
  on(Actions.getQuoteSuccess, (state, { quote }) => ({
    ...state,
    selectedQuote: quote,
    loading: false,
    error: null,
  })),
  on(Actions.getQuoteFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.getQuotesRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.getQuotesSuccess, (state, { quotes }) => {
    return adapter.setAll(quotes, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.getQuotesFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(
    Actions.getQuotesByWorkOrderRequest,
    Actions.getQuotesByWorkOrderRequestFromEffect,
    (state, { id }) => ({
      ...state,
      selectedQuote: null,
      loading: true,
      error: null,
    })
  ),
  on(Actions.getQuotesByWorkOrderSuccess, (state, { quotes }) => {
    return adapter.setAll(quotes, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.getQuotesByWorkOrderFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.addQuoteRequest, (state, { addQuoteInput }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.addQuoteSuccess, (state, { quote }) => {
    return adapter.addOne(quote, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.addQuoteFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.updateQuoteRequest, (state, { editQuoteInput }) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.updateQuoteSuccess, (state, { quote }) => {
    return adapter.upsertOne(quote, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.updateQuoteFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.removeQuoteRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.removeQuoteSuccess, (state, { id }) => {
    return adapter.removeOne(id, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.removeQuoteFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.selectQuoteRequest, (state, { id }) => {
    return {
      ...state,
      loading: false,
      error: null,
    };
  }),
  on(Actions.selectQuoteSuccess, (state, { quote }) => {
    return {
      ...state,
      selectedQuote: quote,
      selectedQuoteId: quote.id,
      loading: false,
      error: null,
    };
  }),
  on(Actions.selectQuoteFailed, (state, { error }) => ({
    ...state,
    selectedQuote: null,
    selectedQuoteId: null,
    loading: false,
    error,
  })),
  on(Actions.duplicateQuoteRequest, (state, { duplicateQuoteInput }) => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),
  on(Actions.duplicateQuoteSuccess, (state, { quote }) => {
    return adapter.addOne(quote, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(Actions.duplicateQuoteFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.clearQuotes, (state) => {
    return adapter.removeAll({
      ...state,
      selectedQuote: null,
    });
  })
);
