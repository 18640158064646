import { createAction, props } from '@ngrx/store';
import { WorkOrder } from '@app/core/models/work-order.model';
import {
  AddWorkOrderInput,
  EditWorkOrderInput,
  DuplicateWorkOrderInput,
  CompleteWorkOrderInput,
  CopyWorkOrderToCustomerInput,
} from '@app/core/models';

export const selectWorkOrderRequest = createAction(
  '[Work Order] Select Work Order',
  props<{ id: number }>()
);
export const selectWorkOrderSuccess = createAction(
  '[Work Order API] Select Work Order Success',
  props<{ workOrder: WorkOrder }>()
);
export const selectWorkOrderFailed = createAction(
  '[Work Order API] Select Work Order Failed',
  props<{ error: string }>()
);

export const getWorkOrderRequest = createAction(
  '[Work Order Details Page] Get Work Order Details',
  props<{ id: number }>()
);
export const getWorkOrderSuccess = createAction(
  '[Work Order API] Get Work Order Details Success',
  props<{ workOrder: WorkOrder }>()
);
export const getWorkOrderFailed = createAction(
  '[Work Order API] Get Work Order Details Failed',
  props<{ error: string }>()
);

export const getWorkOrdersRequest = createAction(
  '[Work Orders] Get Work Orders Request'
);
export const getWorkOrdersSuccess = createAction(
  '[Work Order API] Get Work Orders Success',
  props<{ workOrders: WorkOrder[] }>()
);
export const getWorkOrdersFailed = createAction(
  '[Work Order API] Get Work Orders Failed',
  props<{ error: string }>()
);

export const getWorkOrdersByCustomerRequest = createAction(
  '[Customer Details] Get Work Orders by Customer Request',
  props<{ id: number }>()
);
export const getWorkOrdersByCustomerRequestFromCustomerEffect = createAction(
  '[Customer Effect] Get Work Orders by Customer Request',
  props<{ id: number }>()
);
export const getWorkOrdersByCustomerSuccess = createAction(
  '[Work Order API] Get Work Orders by Customer Success',
  props<{ workOrders: WorkOrder[] }>()
);
export const getWorkOrdersByCustomerFailed = createAction(
  '[Work Order API] Get Work Orders by Customer Failed',
  props<{ error: string }>()
);

export const addWorkOrderRequest = createAction(
  '[Work Order Details] Add Work Order Request',
  props<{ addWorkOrderInput: AddWorkOrderInput }>()
);
export const addWorkOrderSuccess = createAction(
  '[Work Order API] Add Work Order Success',
  props<{ workOrder: WorkOrder }>()
);
export const addWorkOrderFailed = createAction(
  '[Work Order API] Add Work Order Failed',
  props<{ error: string }>()
);

export const updateWorkOrderRequest = createAction(
  '[Work Order Details] Update Work Order Request',
  props<{ editWorkOrderInput: EditWorkOrderInput }>()
);
export const updateWorkOrderSuccess = createAction(
  '[Work Order API] Update Work Order Success',
  props<{ workOrder: WorkOrder }>()
);
export const updateWorkOrderFailed = createAction(
  '[Work Order API] Update Work Order Failed',
  props<{ error: string }>()
);

export const removeWorkOrderRequest = createAction(
  '[Work Order Details] Remove Work Order Request',
  props<{ id: number }>()
);
export const removeWorkOrderSuccess = createAction(
  '[Work Order API] Remove Work Order Success',
  props<{ id: number }>()
);
export const removeWorkOrderFailed = createAction(
  '[Work Order API] Remove Work Order Failed',
  props<{ error: string }>()
);

export const duplicateWorkOrderRequest = createAction(
  '[Work Order Details] Duplicate Work Order Request',
  props<{ duplicateWorkOrderInput: DuplicateWorkOrderInput }>()
);
export const duplicateWorkOrderSuccess = createAction(
  '[Work Order API] Duplicate Work Order Success',
  props<{ workOrder: WorkOrder }>()
);
export const duplicateWorkOrderFailed = createAction(
  '[Work Order API] Duplicate Work Order Failed',
  props<{ error: string }>()
);

export const completeWorkOrderRequest = createAction(
  '[Work Order Details] Complete Work Order Request',
  props<{ completeWorkOrderInput: CompleteWorkOrderInput }>()
);
export const completeWorkOrderSuccess = createAction(
  '[Work Order API] Complete Work Order Success',
  props<{ workOrder: WorkOrder }>()
);
export const completeWorkOrderFailed = createAction(
  '[Work Order API] Complete Work Order Failed',
  props<{ error: string }>()
);

export const clearSelectedWorkOrder = createAction(
  '[Work Order] Clear Selected Work Order'
);

export const customerDetailsClearWorkOrders = createAction(
  '[Customer Details Page] Clear Work Orders'
);

export const getScheduledWorkOrders = createAction(
  '[Scheduled Work Orders] Get Scheduled Work Orders',
  props<{ date: Date }>()
);
export const getScheduledWorkOrdersSuccess = createAction(
  '[Work Order API] Get Scheduled Work Orders Success',
  props<{ workOrders: WorkOrder[] }>()
);
export const getScheduledWorkOrdersFailed = createAction(
  '[Work Order API] Get Scheduled Work Orders Failed',
  props<{ error: string }>()
);

export const copyWorkOrderToCustomerRequest = createAction(
  '[Work Order Details] Copy Work Order to Customer Request',
  props<{ copyWorkOrderToCustomerInput: CopyWorkOrderToCustomerInput }>()
);
export const copyWorkOrderToCustomerSuccess = createAction(
  '[Work Order API] Copy Work Order to Customer Success',
  props<{ workOrder: WorkOrder }>()
);
export const copyWorkOrderToCustomerFailed = createAction(
  '[Work Order API] Copy Work Order to Customer Failed',
  props<{ error: string }>()
);
