import { createAction, props } from '@ngrx/store';
import { CustomerSearchInput } from '@app/core/models/customer-search-input.model';
import { AddCustomerInput, Customer } from '@app/core/models';

export const searchForCustomer = createAction(
  '[Customer Search] Search for Customer',
  props<{ searchInput: CustomerSearchInput }>()
);
export const searchForCustomerSuccess = createAction(
  '[Customer API] Search for Customer Success',
  props<{ customers: Customer[] }>()
);
export const searchForCustomerFailed = createAction(
  '[Customer API] Search for Customer Error',
  props<{ error: string }>()
);
export const setCustomerList = createAction(
  '[Customer Search] Set Customer List',
  props<{ customers: Customer[] }>()
);

export const getCustomerDetails = createAction(
  '[Customer Details Page] Get Customers Details',
  props<{ id: number }>()
);
export const getCustomerDetailsSuccess = createAction(
  '[Customer API] Get Customers Details Success',
  props<{ customer: Customer }>()
);
export const getCustomerDetailsFailed = createAction(
  '[Customer API] Get Customers Details Failed',
  props<{ error: string }>()
);

export const getCustomersRequest = createAction(
  '[Customers] Get Customers Request'
);
export const getCustomersSuccess = createAction(
  '[Customers API] Get Customers Success',
  props<{ customers: Customer[] }>()
);
export const getCustomersFailed = createAction(
  '[Customers API] Get Customers Failed',
  props<{ error: string }>()
);

export const addCustomerRequest = createAction(
  '[Customer Details] Add Customer Request',
  props<{ addCustomerInput: AddCustomerInput }>()
);
export const addCustomerSuccess = createAction(
  '[Customers API] Add Customer Success',
  props<{ customer: Customer }>()
);
export const addCustomerFailed = createAction(
  '[Customers API] Add Customer Failed',
  props<{ error: string }>()
);

export const updateCustomerRequest = createAction(
  '[Customer Details] Update Customer Request',
  props<{ addCustomerInput: AddCustomerInput }>()
);
export const updateCustomerSuccess = createAction(
  '[Customer API] Update Customer Success',
  props<{ customer: Customer }>()
);
export const updateCustomerFailed = createAction(
  '[Customer API] Update Customer Failed',
  props<{ error: string }>()
);

export const removeCustomerRequest = createAction(
  '[Customer Details] Remove Customer Request',
  props<{ id: number }>()
);
export const removeCustomerSuccess = createAction(
  '[Customer API] Remove Customer Success',
  props<{ id: number }>()
);
export const removeCustomerFailed = createAction(
  '[Customer API] Remove Customer Failed',
  props<{ error: string }>()
);

export const clearCustomers = createAction('[Customer Action] Clear Customers');
export const clearCustomersSearch = createAction(
  '[Customer Search] Clear Customers'
);

export const deselectCustomer = createAction('[Customer] Deselect Customer');

// Page specific

// export const customerSearchPageSearchTerm = createAction(
//   '[Customer Search Page] Search for Customer',
//   props<{ searchInput: CustomerSearchInput }>()
// );
