import {
  mergeMap,
  map,
  catchError,
  take,
  tap,
  concatMap,
  first,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as WorkOrderActions from '../actions/work-order.actions';
import * as CustomerActions from '../actions/customer.actions';
import * as CustomerSelectors from '../selectors/customer.selectors';
import * as BuildingActions from '../actions/building.actions';
import * as CustomerCreditActions from '../actions/customer-credit.actions';
import * as BuildingSelectors from '../selectors/building.selectors';
import { WorkOrderService } from '@app/work-orders/services/work-order.service';
import { WorkOrder } from '@app/core/models/work-order.model';
import { Store } from '@ngrx/store';
import { IAppState } from '../state/app.state';
import { Router } from '@angular/router';
import { UserService } from '@app/users/services/user.service';
import { EWorkOrderStatus } from '@app/core/enums/work-order-status';
import { User, CustomerCredit, Customer } from '@app/core/models';
import { CustomerCreditService } from '@app/customer-credit/services/customer-credit.service';

@Injectable()
export class WorkOrderEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.getWorkOrderRequest),
      mergeMap(({ id }) =>
        this.workOrderService.get(id).pipe(
          concatMap((workOrder: WorkOrder) => {
            if (workOrder.status === EWorkOrderStatus.Complete) {
              return this.userService.get(workOrder.completedByUserId).pipe(
                tap(
                  (user: User) =>
                    (workOrder.completedByUserFullName = user.fullName)
                ),
                map(() => workOrder)
              );
            } else {
              return of(workOrder);
            }
          }),
          // concatMap((workOrder: WorkOrder) => {
          //   if (workOrder.customerCreditId ) {
          //     return this.customerCreditService
          //       .get<CustomerCredit>(workOrder.customerCreditId)
          //       .pipe(
          //         tap(
          //           (customerCredit) =>
          //             (workOrder.customerCredit = customerCredit)
          //         ),
          //         map(() => workOrder)
          //       );
          //   } else {
          //     return of(workOrder);
          //   }
          // }),
          tap((workOrder: WorkOrder) => {
            const sub$ = this.store
              .select(CustomerSelectors.getSelectedCustomer)
              .pipe(
                first(),
                tap((customer: Customer) => {
                  if (customer == null) {
                    this.store.dispatch(
                      CustomerActions.getCustomerDetails({
                        id: workOrder.customerId,
                      })
                    );
                    this.store.dispatch(
                      BuildingActions.getBuildingsByCustomerRequest({
                        id: workOrder.customerId,
                      })
                    );
                    this.store.dispatch(
                      CustomerCreditActions.getCustomerCreditByCustomerRequest({
                        id: workOrder.customerId,
                      })
                    );
                  }
                })
              )
              .subscribe();
            sub$.unsubscribe();
          }),
          map((workOrder: WorkOrder) =>
            WorkOrderActions.getWorkOrderSuccess({ workOrder })
          ),
          catchError((error) =>
            of(
              WorkOrderActions.getWorkOrderFailed({
                error,
              })
            )
          )
        )
      )
    )
  );
  getByCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkOrderActions.getWorkOrdersByCustomerRequest,
        WorkOrderActions.getWorkOrdersByCustomerRequestFromCustomerEffect
      ),
      mergeMap(({ id }) =>
        this.workOrderService.getAllByCustomerId(id).pipe(
          map((workOrders: WorkOrder[]) =>
            WorkOrderActions.getWorkOrdersByCustomerSuccess({ workOrders })
          ),
          catchError((error) =>
            of(
              WorkOrderActions.getWorkOrdersByCustomerFailed({
                error,
              })
            )
          )
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.addWorkOrderRequest),
      mergeMap(({ addWorkOrderInput }) =>
        this.workOrderService.create(addWorkOrderInput).pipe(
          map((response: WorkOrder) => {
            // this.store.dispatch(
            //   QuoteActions.getQuotesByWorkOrderRequestFromEffect({
            //     id: response.id,
            //   })
            // );
            // this.store.dispatch(
            //   WorkOrderActions.selectWorkOrderRequest({ id: response.id })
            // );
            return WorkOrderActions.addWorkOrderSuccess({
              workOrder: response,
            });
          }),
          catchError((error) =>
            of(WorkOrderActions.addWorkOrderFailed({ error }))
          )
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.removeWorkOrderRequest),
      mergeMap(({ id }) =>
        this.workOrderService.delete(id).pipe(
          map(() => WorkOrderActions.removeWorkOrderSuccess({ id })),
          catchError((error) =>
            of(WorkOrderActions.removeWorkOrderFailed({ error }))
          )
        )
      )
    )
  );
  // deleteSuccess$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(WorkOrderActions.updateWorkOrderSuccess),
  //       tap(({ workOrder }) => {
  //         this.router.navigate(['/customer', workOrder.id]);
  //       })
  //     ),
  //   { dispatch: false }
  // );

  // deleteRedirectToCustomer$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(WorkOrderActions.removeWorkOrderRequest),
  //     mergeMap(({ id }) =>
  //       this.workOrderService.delete(id).pipe(
  //         map(() => WorkOrderActions.removeWorkOrderSuccess({ id })),
  //         tap(() => {
  //           this.router.navigate(['/work-order', workOrder.id]);
  //         }),
  //         catchError((error) =>
  //           of(WorkOrderActions.removeWorkOrderFailed({ error }))
  //         )
  //       )
  //     )
  //   )
  // );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.updateWorkOrderRequest),
      mergeMap(({ editWorkOrderInput }) =>
        this.workOrderService.update(editWorkOrderInput).pipe(
          map((workOrder: WorkOrder) =>
            WorkOrderActions.updateWorkOrderSuccess({ workOrder })
          ),
          catchError((error) =>
            of(WorkOrderActions.updateWorkOrderFailed({ error }))
          )
        )
      )
    )
  );
  updateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkOrderActions.updateWorkOrderSuccess),
        tap(({ workOrder }) => {
          this.router.navigate(['/work-order', workOrder.id]);
        })
      ),
    { dispatch: false }
  );

  complete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.completeWorkOrderRequest),
      mergeMap(({ completeWorkOrderInput }) =>
        this.workOrderService.complete<WorkOrder>(completeWorkOrderInput).pipe(
          concatMap((workOrder: WorkOrder) =>
            this.userService.get(workOrder.completedByUserId).pipe(
              tap(
                (user: User) =>
                  (workOrder.completedByUserFullName = user.fullName)
              ),
              map(() => workOrder)
            )
          ),
          map((workOrder) => {
            return WorkOrderActions.completeWorkOrderSuccess({ workOrder });
          }),
          catchError((error) =>
            of(WorkOrderActions.completeWorkOrderFailed({ error }))
          )
        )
      )
    )
  );

  duplicate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.duplicateWorkOrderRequest),
      mergeMap(({ duplicateWorkOrderInput }) =>
        this.workOrderService
          .duplicate<WorkOrder>(duplicateWorkOrderInput)
          .pipe(
            map((workOrder) =>
              WorkOrderActions.duplicateWorkOrderSuccess({ workOrder })
            ),
            catchError((error) =>
              of(WorkOrderActions.duplicateWorkOrderFailed({ error }))
            )
          )
      )
    )
  );
  duplicateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkOrderActions.duplicateWorkOrderSuccess),
        tap(({ workOrder }) => {
          this.router.navigate([`work-order/${workOrder.id}/edit`]);
          // this.router.navigate(['/work-order', workOrder.id]);
        })
      ),
    { dispatch: false }
  );

  getScheduled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkOrderActions.getScheduledWorkOrders),
      mergeMap(({ date }) =>
        this.workOrderService.getByDate(date).pipe(
          map((workOrders: WorkOrder[]) =>
            WorkOrderActions.getScheduledWorkOrdersSuccess({ workOrders })
          ),
          catchError((error) =>
            of(
              WorkOrderActions.getScheduledWorkOrdersFailed({
                error,
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private workOrderService: WorkOrderService,
    private customerCreditService: CustomerCreditService,
    private userService: UserService,
    private store: Store<IAppState>,
    private actions$: Actions,
    private router: Router
  ) {}
}
