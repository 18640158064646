import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { User } from '@app/core/models';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  rolesAllowedForCosts = ['Admin', 'Super Admin'];

  constructor(http: HttpClient) {
    super('User', http);
  }

  isUserInRole(user: User, roleName: string): boolean {
    return Array.isArray(user.roleNames)
      ? user.roleNames?.filter((userRole) => userRole === roleName).length > 0
      : user.roleNames === roleName;
  }

  isUserInRoleArray(user: User, roleNames: string[]): boolean {
    const userRole = user?.roleNames as unknown as string;
    return Array.isArray(user?.roleNames)
      ? user.roleNames?.filter((role) => roleNames.includes(role)).length > 0
      : roleNames.find((role) => role === userRole) !== undefined;
  }
}
