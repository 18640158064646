import { Component, OnInit } from '@angular/core';
import { IAppState } from '@store/state/app.state';
import { Store } from '@ngrx/store';
import * as AuthSelectors from '@store/selectors/auth.selectors';
import * as AuthActions from '@store/actions/auth.actions';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
})
export class LoginFormComponent implements OnInit {
  loggedIn$ = this.store.select(AuthSelectors.selectLoggedIn);
  loading$ = this.store.select(AuthSelectors.selectLoading);
  error$ = this.store.select(AuthSelectors.selectError);

  constructor(private store: Store<IAppState>) {}

  ngOnInit(): void {}

  login(creds: string[]): void {
    this.store.dispatch(AuthActions.loginPageLoginRequest({ creds }));
  }
  logout(): void {
    this.store.dispatch(AuthActions.loginPageLogoutRequest());
  }
}
