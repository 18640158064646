import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/auth.actions';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { User } from '@app/core/models';
import { IAuthState } from '../state/auth.state';

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialAuthState: IAuthState = adapter.getInitialState({
  isAuthenticated: false,
  user: null,
  loading: false,
  error: null,
});

export const authReducers = createReducer(
  initialAuthState,
  on(Actions.getUserRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.getUserSuccess, (state, { user }) => ({
    ...state,
    user,
    loading: false,
    error: null,
  })),
  on(Actions.getUserFailed, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(Actions.loginStatusRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.loginStatusSuccess, (state, { isLoggedIn }) => ({
    ...state,
    isAuthenticated: isLoggedIn,
    loading: false,
    error: null,
  })),
  on(Actions.loginPageLoginRequest, Actions.loginRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.loginSuccess, (state, { user }) => {
    return {
      ...state,
      user,
      isAuthenticated: true,
      loading: false,
      error: null,
    };
  }),
  on(Actions.loginFailed, (state, { error }) => ({
    ...state,
    isAuthenticated: false,
    loading: false,
    error,
  })),
  on(Actions.logoutRequest, Actions.loginPageLogoutRequest, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(Actions.logoutSuccess, (state) => {
    return {
      ...state,
      isAuthenticated: false,
      loading: false,
      error: null,
    };
  }),
  on(Actions.logoutFailed, (state, { error }) => ({
    ...state,
    isAuthenticated: false,
    loading: false,
    error,
  }))
);
