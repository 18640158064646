import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CleaningCostTierService extends BaseService {
  constructor(protected http: HttpClient) {
    super('CleaningCostTier', http);
  }
}
