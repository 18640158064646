import { IAppState } from '../state/app.state';
import { createSelector } from '@ngrx/store';
import { IErrorState } from '../state/error.state';

const errorSelectors = (state: IAppState) => state.errors;

export const selectError = createSelector(
  errorSelectors,
  (state: IErrorState) => state.error
);
export const selectErrorMessage = createSelector(
  errorSelectors,
  (state: IErrorState) => state.errorMessage
);
