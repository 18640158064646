import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap, map, share } from 'rxjs/operators';
import { ApiResponse } from '@app/core/models';
import { WorkOrder } from '@app/core/models/work-order.model';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderService extends BaseService {
  constructor(protected http: HttpClient) {
    super('WorkOrder', http);
  }

  getAllByCustomerId(id: number): Observable<WorkOrder[]> {
    return this.http.get(this.apiUrl + '/GetByCustomer?id=' + id).pipe(
      map((response: ApiResponse) => response.result.items as WorkOrder[]),
      share()
    );
  }

  getToday(): Observable<WorkOrder[]> {
    return this.http.get(this.apiUrl + '/GetToday').pipe(
      map((response: ApiResponse) => response.result.items as WorkOrder[]),
      share()
    );
  }

  getByDate(date: Date): Observable<WorkOrder[]> {
    // Convert date to this format 2012-12-31
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const dateString = `${year}-${month}-${day}`;
    return this.http.get(this.apiUrl + '/GetByDate?date=' + dateString).pipe(
      map((response: ApiResponse) => response.result.items as WorkOrder[]),
      share()
    );
  }

  complete<T>(data: any): Observable<T> {
    return this.http.post(`${this.apiUrl}/Complete`, data).pipe(
      map((response: ApiResponse) => response.result as T),
      share()
    );
  }

  duplicate<T>(data: any): Observable<T> {
    return this.http.post(`${this.apiUrl}/Duplicate`, data).pipe(
      map((response: ApiResponse) => response.result as T),
      share()
    );
  }
}
