import { mergeMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CleaningCostTierActions from '../actions/cleaning-cost-tier.actions';
import { CleaningCostTierService } from '@app/cleaning-costs/services/cleaning-cost-tier.service';
import { CleaningCostTier } from '@app/core/models/cleaning-cost-tier.model';

@Injectable()
export class CleaningCostTierEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CleaningCostTierActions.getCleaningCostTierRequest),
      mergeMap(({ id }) =>
        this.cleaningCostTierService.get(id).pipe(
          map((cleaningCostTier: CleaningCostTier) =>
            CleaningCostTierActions.getCleaningCostTierSuccess({
              cleaningCostTier,
            })
          ),
          catchError((error) =>
            of(
              CleaningCostTierActions.getCleaningCostTierFailed({
                error,
              })
            )
          )
        )
      )
    )
  );

  getAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CleaningCostTierActions.getCleaningCostTiersRequest),
      mergeMap(() =>
        this.cleaningCostTierService.getAll<CleaningCostTier[]>().pipe(
          map((cleaningCostTiers) =>
            CleaningCostTierActions.getCleaningCostTiersSuccess({
              cleaningCostTiers,
            })
          ),
          catchError((err) =>
            of(
              CleaningCostTierActions.getCleaningCostTiersFailed({
                error: err.message,
              })
            )
          )
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CleaningCostTierActions.addCleaningCostTierRequest),
      mergeMap(({ addCleaningCostTierInput }) =>
        this.cleaningCostTierService.create(addCleaningCostTierInput).pipe(
          map((response: CleaningCostTier) =>
            CleaningCostTierActions.addCleaningCostTierSuccess({
              cleaningCostTier: response,
            })
          ),
          catchError((error) =>
            of(CleaningCostTierActions.addCleaningCostTierFailed({ error }))
          )
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CleaningCostTierActions.removeCleaningCostTierRequest),
      mergeMap(({ id }) =>
        this.cleaningCostTierService.delete(id).pipe(
          map(() =>
            CleaningCostTierActions.removeCleaningCostTierSuccess({ id })
          ),
          catchError((error) =>
            of(CleaningCostTierActions.removeCleaningCostTierFailed({ error }))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CleaningCostTierActions.updateCleaningCostTierRequest),
      mergeMap(({ editCleaningCostTierInput }) =>
        this.cleaningCostTierService.update(editCleaningCostTierInput).pipe(
          map((cleaningCostTier: CleaningCostTier) =>
            CleaningCostTierActions.updateCleaningCostTierSuccess({
              cleaningCostTier,
            })
          ),
          catchError((error) =>
            of(CleaningCostTierActions.updateCleaningCostTierFailed({ error }))
          )
        )
      )
    )
  );

  constructor(
    private cleaningCostTierService: CleaningCostTierService,
    private actions$: Actions
  ) {}
}
