import { IAppState } from '../state/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICustomerState } from '../state/customer.state';
import { adapter } from '../reducers/customer.reducers';

const selectCustomers = (state: IAppState) => state.customers;

export const selectUserState = createFeatureSelector<ICustomerState>(
  'customers'
);

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the dictionary of user entities
export const selectCustomerEntities = selectEntities;
// select the array of users
export const selectAllCustomers = createSelector(selectUserState, selectAll);

export const getSelectedCustomer = createSelector(
  selectCustomers,
  (state: ICustomerState) => state.selectedCustomer
);

export const selectLoading = createSelector(
  selectCustomers,
  (state: ICustomerState) => state.loading
);
export const selectLoadingList = createSelector(
  selectCustomers,
  (state: ICustomerState) => state.loadingList
);
export const selectError = createSelector(
  selectCustomers,
  (state: ICustomerState) => state.error
);
export const selectCustomerSearchList = createSelector(
  selectCustomers,
  (state: ICustomerState) => state.customerSearchList
);

const getRouterState = createFeatureSelector('routerReducer');
