import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap, map, share } from 'rxjs/operators';
import { ApiResponse } from '@app/core/models';
import { Building } from '@app/core/models/building.model';

@Injectable({
  providedIn: 'root',
})
export class BuildingService extends BaseService {
  constructor(protected http: HttpClient) {
    super('Building', http);
  }

  getBuildingsByCustomer(id: number): Observable<Building[]> {
    return this.http.get(this.apiUrl + '/GetByCustomer?id=' + id).pipe(
      map((response: ApiResponse) => response.result.items as Building[]),
      share()
    );
  }

  getBuildingsWithMaintenanceRequest(): Observable<Building[]> {
    return this.http.get(this.apiUrl + '/GetWithMaintenance').pipe(
      map((response: ApiResponse) => response.result.items as Building[]),
      share()
    );
  }

  getMaintenanceOverdueCount(): Observable<number> {
    return this.http.get(this.apiUrl + '/GetMaintenanceOverdueCount').pipe(
      map((response: ApiResponse) => response.result),
      share()
    );
  }
}
