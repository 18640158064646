import { IAppState } from '../state/app.state';
import { createSelector } from '@ngrx/store';
import { IAuthState } from '../state/auth.state';

const selectAuth = (state: IAppState) => state.auth;

export const selectUser = createSelector(
  selectAuth,
  (state: IAuthState) => state.user
);
export const selectLoggedIn = createSelector(
  selectAuth,
  (state: IAuthState) => state.isAuthenticated
);

export const selectLoading = createSelector(
  selectAuth,
  (state: IAuthState) => state.loading
);
export const selectError = createSelector(
  selectAuth,
  (state: IAuthState) => state.error
);
