import { mergeMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as UserActions from '../actions/user.actions';
import { User } from '@app/core/models/user.model';
import { UserService } from '@app/shared/services/user.service';

@Injectable()
export class UserEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserRequest),
      mergeMap(({ id }) =>
        this.userService.get(id).pipe(
          map((user: User) =>
            UserActions.getUserSuccess({
              user,
            })
          ),
          catchError((error) =>
            of(
              UserActions.getUserFailed({
                error,
              })
            )
          )
        )
      )
    )
  );

  getAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUsersRequest),
      mergeMap(() =>
        this.userService.getAll<User[]>().pipe(
          map((users) => UserActions.getUsersSuccess({ users })),
          catchError((error) => of(UserActions.getUsersFailed({ error })))
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.addUserRequest),
      mergeMap(({ addUserInput }) =>
        this.userService.create(addUserInput).pipe(
          map((response: User) =>
            UserActions.addUserSuccess({
              user: response,
            })
          ),
          catchError((error) => of(UserActions.addUserFailed({ error })))
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.removeUserRequest),
      mergeMap(({ id }) =>
        this.userService.delete(id).pipe(
          map(() => UserActions.removeUserSuccess({ id })),
          catchError((error) => of(UserActions.removeUserFailed({ error })))
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserRequest),
      mergeMap(({ editUserInput }) =>
        this.userService.update(editUserInput).pipe(
          map((user: User) =>
            UserActions.updateUserSuccess({
              user,
            })
          ),
          catchError((error) => of(UserActions.updateUserFailed({ error })))
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.resetPassword),
      mergeMap(({ resetPasswordDto }) =>
        this.userService.resetPassword(resetPasswordDto).pipe(
          map((success: boolean) =>
            UserActions.resetPasswordSuccess({
              success,
            })
          ),
          catchError((error) => of(UserActions.resetPasswordFailed({ error })))
        )
      )
    )
  );

  constructor(private userService: UserService, private actions$: Actions) {}
}
