import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { IAppState } from '@app/store/state/app.state';
import { Store } from '@ngrx/store';
import { AuthService } from './auth.service';
import * as AuthSelectors from '@store/selectors/auth.selectors';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  user$ = this.store
    .select(AuthSelectors.selectUser)
    .pipe(
      tap((user) => {
        if (Array.isArray(user?.roleNames)) {
          this.userRoles = user?.roleNames;
        } else {
          this.userRole = (user?.roleNames as unknown) as string;
        }
      })
    )
    .subscribe();

  userRoles: string[];
  userRole: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<IAppState>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const loggedIn = this.authService.isLoggedIn();
    const roles = route.data.roles as Array<string>;
    if (
      loggedIn && Array.isArray(this.userRoles)
        ? this.userRoles.filter((userRole) => roles.includes(userRole)).length >
          0
        : roles.find((role) => role === this.userRole)
    ) {
      this.user$.unsubscribe();
      return true;
    }
    this.user$.unsubscribe();

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/customer/search'], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}
