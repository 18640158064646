import {
  mergeMap,
  map,
  catchError,
  take,
  tap,
  concatMap,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
// import { BuildingService } from '@app/buildings/services/buildings.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as BuildingActions from '../actions/building.actions';
import { BuildingService } from '@app/buildings/services/building.service';
import { Building } from '@app/core/models/building.model';
import { EMaintenanceStatus } from '@app/core/enums';
import { Customer } from '@app/core/models';
import { CustomerService } from '@app/customers/services/customer.service';

@Injectable()
export class BuildingEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BuildingActions.getBuildingRequest),
      mergeMap(({ id }) =>
        this.buildingService.get(id).pipe(
          tap((building: Building) => this.setMaintenanceStatus(building)),
          map((building: Building) =>
            BuildingActions.getBuildingSuccess({ building })
          ),
          catchError((error) =>
            of(
              BuildingActions.getBuildingFailed({
                error,
              })
            )
          )
        )
      )
    )
  );
  getByCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BuildingActions.getBuildingsByCustomerRequest,
        BuildingActions.getBuildingsByCustomerRequestFromCustomerEffect
      ),
      mergeMap(({ id }) =>
        this.buildingService.getBuildingsByCustomer(id).pipe(
          tap((buildings: Building[]) => {
            buildings.forEach((building) => {
              this.setMaintenanceStatus(building);
            });
          }),
          map((buildings: Building[]) =>
            BuildingActions.getBuildingsByCustomerSuccess({ buildings })
          ),
          catchError((error) =>
            of(
              BuildingActions.getBuildingsByCustomerFailed({
                error,
              })
            )
          )
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BuildingActions.addBuildingRequest),
      mergeMap(({ addBuildingInput }) =>
        this.buildingService.create(addBuildingInput).pipe(
          tap((building: Building) => this.setMaintenanceStatus(building)),
          map((response: Building) =>
            BuildingActions.addBuildingSuccess({ building: response })
          ),
          catchError((error) =>
            of(BuildingActions.addBuildingFailed({ error }))
          )
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BuildingActions.removeBuildingRequest),
      mergeMap(({ id }) =>
        this.buildingService.delete(id).pipe(
          map(() => BuildingActions.removeBuildingSuccess({ id })),
          catchError((error) =>
            of(BuildingActions.removeBuildingFailed({ error }))
          )
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BuildingActions.updateBuildingRequest),
      mergeMap(({ editBuildingInput }) =>
        this.buildingService.update(editBuildingInput).pipe(
          tap((building: Building) => this.setMaintenanceStatus(building)),
          concatMap((building) =>
            this.customerService.get<Customer>(building.customerId).pipe(
              tap((customer) => (building.customer = customer)),
              map(() => building)
            )
          ),
          map((building: Building) =>
            BuildingActions.updateBuildingSuccess({ building })
          ),
          catchError((error) =>
            of(BuildingActions.updateBuildingFailed({ error }))
          )
        )
      )
    )
  );

  getBuildingsWithMaintenance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BuildingActions.getBuildingsWithMaintenanceRequest),
      mergeMap(() =>
        this.buildingService.getBuildingsWithMaintenanceRequest().pipe(
          tap((buildings: Building[]) => {
            buildings.forEach((building) => {
              this.setMaintenanceStatus(building);
            });
          }),
          map((buildings: Building[]) =>
            BuildingActions.getBuildingsSuccess({ buildings })
          ),
          catchError((error) =>
            of(BuildingActions.getBuildingsFailed({ error }))
          )
        )
      )
    )
  );

  getMaintenanceOverdueCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BuildingActions.getOverdueMaintenanceCountRequest),
      mergeMap(() =>
        this.buildingService.getMaintenanceOverdueCount().pipe(
          map((count: number) =>
            BuildingActions.getOverdueMaintenanceCountSuccess({ count })
          ),
          catchError((error) =>
            of(BuildingActions.getOverdueMaintenanceCountFailed({ error }))
          )
        )
      )
    )
  );

  private setMaintenanceStatus(building: Building): void {
    const today = new Date(Date.now());
    const maintenanceDate = new Date(building.maintenanceDate);
    if (building.maintenanceDate == null) {
      building.status = EMaintenanceStatus.Not_Set;
    } else if (
      maintenanceDate.getTime() < today.getTime() &&
      !building.isMaintenanceCalledBack
    ) {
      building.status = EMaintenanceStatus.Past_Due;
    } else if (building.isMaintenanceCalledBack) {
      building.status = EMaintenanceStatus.Called;
    } else {
      building.status = EMaintenanceStatus.Not_Called;
    }
  }

  constructor(
    private buildingService: BuildingService,
    private customerService: CustomerService,
    private actions$: Actions
  ) {}
}
