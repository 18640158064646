import { createAction, props } from '@ngrx/store';
import {
  AddCleaningCostTierInput,
  EditCleaningCostTierInput,
  CleaningCostTier,
} from '@app/core/models';

export const getCleaningCostTierRequest = createAction(
  '[Cleaning Cost Tier Details Page] Get CleaningCostTier Details',
  props<{ id: number }>()
);
export const getCleaningCostTierSuccess = createAction(
  '[Cleaning Cost Tier API] Get CleaningCostTier Details Success',
  props<{ cleaningCostTier: CleaningCostTier }>()
);
export const getCleaningCostTierFailed = createAction(
  '[Cleaning Cost Tier API] Get CleaningCostTier Details Failed',
  props<{ error: string }>()
);

export const getCleaningCostTiersRequest = createAction(
  '[Cleaning Cost Tiers] Get CleaningCostTiers Request'
);
export const getCleaningCostTiersSuccess = createAction(
  '[Cleaning Cost Tiers API] Get CleaningCostTiers Success',
  props<{ cleaningCostTiers: CleaningCostTier[] }>()
);
export const getCleaningCostTiersFailed = createAction(
  '[Cleaning Cost Tiers API] Get CleaningCostTiers Failed',
  props<{ error: string }>()
);

export const addCleaningCostTierRequest = createAction(
  '[Cleaning Cost Tier Details] Add CleaningCostTier Request',
  props<{ addCleaningCostTierInput: AddCleaningCostTierInput }>()
);
export const addCleaningCostTierSuccess = createAction(
  '[Cleaning Cost Tiers API] Add CleaningCostTier Success',
  props<{ cleaningCostTier: CleaningCostTier }>()
);
export const addCleaningCostTierFailed = createAction(
  '[Cleaning Cost Tiers API] Add CleaningCostTier Failed',
  props<{ error: string }>()
);

export const updateCleaningCostTierRequest = createAction(
  '[Cleaning Cost Tier Details] Update CleaningCostTier Request',
  props<{ editCleaningCostTierInput: EditCleaningCostTierInput }>()
);
export const updateCleaningCostTierSuccess = createAction(
  '[Cleaning Cost Tier API] Update CleaningCostTier Success',
  props<{ cleaningCostTier: CleaningCostTier }>()
);
export const updateCleaningCostTierFailed = createAction(
  '[Cleaning Cost Tier API] Update CleaningCostTier Failed',
  props<{ error: string }>()
);

export const removeCleaningCostTierRequest = createAction(
  '[Cleaning Cost Tier Details] Remove CleaningCostTier Request',
  props<{ id: number }>()
);
export const removeCleaningCostTierSuccess = createAction(
  '[Cleaning Cost Tier API] Remove CleaningCostTier Success',
  props<{ id: number }>()
);
export const removeCleaningCostTierFailed = createAction(
  '[Cleaning Cost Tier API] Remove CleaningCostTier Failed',
  props<{ error: string }>()
);
