import { createAction, props } from '@ngrx/store';
import { Building } from '@app/core/models/building.model';
import { AddBuildingInput, EditBuildingInput } from '@app/core/models';

export const selectBuildingRequest = createAction(
  '[Building] Select Building',
  props<{ id: number }>()
);

export const getBuildingRequest = createAction(
  '[Building Details Page] Get Building Details',
  props<{ id: number }>()
);
export const getBuildingSuccess = createAction(
  '[Building API] Get Building Details Success',
  props<{ building: Building }>()
);
export const getBuildingFailed = createAction(
  '[Building API] Get Building Details Failed',
  props<{ error: string }>()
);

export const getBuildingsRequest = createAction(
  '[Buildings] Get Buildings Request'
);
export const getBuildingsSuccess = createAction(
  '[Buildings API] Get Buildings Success',
  props<{ buildings: Building[] }>()
);
export const getBuildingsFailed = createAction(
  '[Buildings API] Get Buildings Failed',
  props<{ error: string }>()
);

export const getBuildingsByCustomerRequest = createAction(
  '[Customer Details] Get Buildings by Customer Request',
  props<{ id: number }>()
);
export const getBuildingsByCustomerRequestFromCustomerEffect = createAction(
  '[Customer Effect] Get Buildings by Customer Request',
  props<{ id: number }>()
);
export const getBuildingsByCustomerSuccess = createAction(
  '[Buildings API] Get Buildings by Customer Success',
  props<{ buildings: Building[] }>()
);
export const getBuildingsByCustomerFailed = createAction(
  '[Buildings API] Get Buildings by Customer Failed',
  props<{ error: string }>()
);

export const addBuildingRequest = createAction(
  '[Building Details] Add Building Request',
  props<{ addBuildingInput: AddBuildingInput }>()
);
export const addBuildingSuccess = createAction(
  '[Buildings API] Add Building Success',
  props<{ building: Building }>()
);
export const addBuildingFailed = createAction(
  '[Buildings API] Add Building Failed',
  props<{ error: string }>()
);

export const updateBuildingRequest = createAction(
  '[Building Details] Update Building Request',
  props<{ editBuildingInput: EditBuildingInput }>()
);
export const updateBuildingSuccess = createAction(
  '[Building API] Update Building Success',
  props<{ building: Building }>()
);
export const updateBuildingFailed = createAction(
  '[Building API] Update Building Failed',
  props<{ error: string }>()
);

export const removeBuildingRequest = createAction(
  '[Building Details] Remove Building Request',
  props<{ id: number }>()
);
export const removeBuildingSuccess = createAction(
  '[Building API] Remove Building Success',
  props<{ id: number }>()
);
export const removeBuildingFailed = createAction(
  '[Building API] Remove Building Failed',
  props<{ error: string }>()
);

export const getBuildingsWithMaintenanceRequest = createAction(
  '[Buildings] Get Buildings with Maintenance Request'
);

export const getOverdueMaintenanceCountRequest = createAction(
  '[Building API] Get Overdue Maintenance Count Request'
);
export const getOverdueMaintenanceCountSuccess = createAction(
  '[Building API] Get Overdue Maintenance Count Success',
  props<{ count: number }>()
);
export const getOverdueMaintenanceCountFailed = createAction(
  '[Building API] Get Overdue Maintenance Count Failed',
  props<{ error: string }>()
);

export const customerDetailsClearBuildings = createAction(
  '[Customer Details Page] Clear Buildings'
);
