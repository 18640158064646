import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-form-view',
  templateUrl: './login-form-view.component.html',
  styleUrls: ['./login-form-view.component.scss'],
})
export class LoginFormViewComponent implements OnInit {
  form: FormGroup;

  @Input() loggedIn: boolean;
  @Input() loading: boolean;
  @Input() error: string;

  @Output() logIn = new EventEmitter<any>();
  @Output() logOut = new EventEmitter();

  constructor(private fb: FormBuilder, private router: Router) {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.loggedIn) {
      this.router.navigate(['work-order/scheduled']);
    }
  }

  login(): void {
    const val = this.form.value;
    this.logIn.emit([val.email, val.password]);
  }
  logout(): void {
    this.logOut.emit();
  }
}
