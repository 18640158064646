import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Moment } from 'moment';
import { BaseService } from '../services/base.service';
import { catchError, map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(protected http: HttpClient) {
    super('TokenAuth', http);
  }

  login(UserNameOrEmailAddress: string, password: string): Observable<any> {
    // this.loadingSubject.next(true);
    return this.http
      .post<any>(`${environment.baseUrl}api/TokenAuth/Authenticate`, {
        UserNameOrEmailAddress,
        password,
      })
      .pipe(map((response) => response));
  }

  public setSession(authResult): void {
    const expiresAt = moment().add(authResult.expireInSeconds, 'second');
    localStorage.setItem('id_token', authResult.accessToken);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  }

  logout(): void {
    // this.loggedInSubject.next(false);
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
  }

  public getInitialLoggedInStatus(): void {
    console.log('is logged in?', this.isLoggedIn());
    // this.isLoggedIn() ? this.loggedInSubject.next(true) : this.loggedInSubject.next(false);
  }

  public isLoggedIn(): boolean {
    const loggedIn = moment().isBefore(this.getExpiration());
    // if (!loggedIn) {
    //   this.loggedInSubject.next(false);
    // }
    return loggedIn;
  }

  public getFromJWT(parameter: string): any {
    const decodedToken = jwt_decode(localStorage.getItem('id_token'));
    return decodedToken[parameter];
  }

  // public fakeLogin() {
  //   this.loggedInSubject.next(true);
  // }
  // public fakeLogout() {
  //   this.loggedInSubject.next(false);
  // }

  isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  getExpiration(): Moment {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }
}
