import { props, createAction } from '@ngrx/store';
import { ServerError } from '@app/core/models';
import { HttpErrorResponse } from '@angular/common/http';

export const addGlobalError = createAction(
  '[Error Interceptor] Add Global Error',
  props<{ error: HttpErrorResponse }>()
);
export const addGlobalErrorMessage = createAction(
  '[Error Interceptor] Add Global Error Message',
  props<{ errorMessage: string }>()
);
