import { props, createAction } from '@ngrx/store';
import { User } from '@app/core/models';

// export const getUserRequest = createAction(
//   '[Login Page] Get User Details',
//   props<{ id: number }>()
// );
export const getUserRequest = createAction('[Login Page] Get User Details');
export const getUserSuccess = createAction(
  '[Auth API] Get User Success',
  props<{ user: User }>()
);
export const getUserFailed = createAction(
  '[Auth API] Get User Failed',
  props<{ error: string }>()
);

export const loginStatusRequest = createAction('[Auth] Login Status Request');
export const loginStatusSuccess = createAction(
  '[Auth] Login Status Success',
  props<{ isLoggedIn: boolean }>()
);

export const loginPageLoginRequest = createAction(
  '[Login Page] Login',
  props<{ creds: string[] }>()
);

export const loginRequest = createAction(
  '[Auth] Login Request',
  props<{ creds: string[] }>()
);
export const loginSuccess = createAction(
  '[Auth API] Login Success',
  props<{ user: User }>()
);
export const loginFailed = createAction(
  '[Auth API] Login Failed',
  props<{ error: string }>()
);

export const logoutRequest = createAction('[Auth] Logout Request');
export const loginPageLogoutRequest = createAction(
  '[Login Page] Logout Request'
);
export const logoutSuccess = createAction('[Auth API] Logout Success');
export const logoutFailed = createAction(
  '[Auth API] Logout Failed',
  props<{ error: string }>()
);
