import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
})
export class ProgressSpinnerComponent implements OnInit {
  // @Input() loading: boolean;
  @Input() size = 25;

  @Input()
  set loading(val: boolean) {
    if (val && val === true) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    /** spinner starts on init */
    // this.spinner.show();
  }
}
