export enum ERoomType {
  Rec_Room = 'Rec Room',
  Family_Room = 'Family Room',
  Living_Room = 'Living Room',
  Bonus_Room = 'Bonus Room',
  Den = 'Den',
  TV_Room = 'TV Room',
  Theatre_Room = 'Theatre Room',
  Play_Room = 'Play Room',
  Office = 'Office',
  Sunroom = 'Sunroom',
  Primary_Bedroom = 'Primary Bedroom',
  Bedroom_1 = 'Bedroom 1',
  Bedroom_2 = 'Bedroom 2',
  Bedroom_3 = 'Bedroom 3',
  Bedroom_4 = 'Bedroom 4',
  Closet = 'Closet',
  Walk_In_Closet = 'Walk In Closet',
  Hall = 'Hall',
  Landing = 'Landing',
  Entry = 'Entry',
  Kitchen = 'Kitchen',
  Bathroom = 'Bathroom',
  Dining_Room = 'Dining Room',
}
